angular.module('main').directive('disableNegativeNumber', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attr, ctrl) {

            function customValidator(ngModelValue) {
                if(ngModelValue >= 0){
                    ctrl.$setValidity('negativeNumber', true);
                } else if (ngModelValue < 0) {
                    ctrl.$setValidity('negativeNumber', false);
                    return 0;
                }
                return ngModelValue;
            }
            ctrl.$parsers.push(customValidator);
        }
    };
});