
angular.module('main')
.controller('MeurhSolicitacoesalteracoesenderecosFormEditController', [
    'MeurhSolicitacoesalteracoesenderecosConfirmFecharService',
    '$scope', '$stateParams', '$state', 'MeurhSolicitacoesalteracoesenderecos', 'entity', 'NewToaster', '$rootScope', 'MeurhAcompanhamentoService', 'TiposDocumentosRequeridosService',function (MeurhSolicitacoesalteracoesenderecosConfirmFecharService,
        $scope, $stateParams, $state, entityService, entity, NewToaster, $rootScope, MeurhAcompanhamentoService, TiposDocumentosRequeridosService) {

  var self = this;
  self.action = 'update';
  self.entity = entity;
  self.editanexo = true;
  self.alterouanexo = false;
  self.adicionarAnexo = false;
  self.form = {};
  self.formAnexo = {};
  self.entityAnexo = {};
  self.actionAnexo = 'update';
  self.constructors = [];
  self.entityAnexoLista = [];
  self.mimeType;
  self.filelist = [];
  self.LABELS = {
    '0': 'label-warning',
    '1': 'label-success',
    '2': 'label-danger'
  };

  self.SITUACOES = {
    '0': 'Aberta',
    '1': 'Efetuada',
    '2': 'Cancelada',
    '4': 'Recusada',
  };

  self.getLabels = function (situacao) {
    return self.LABELS[situacao];
  }

  self.getSituacao = function (situacao) {
    return self.SITUACOES[situacao];
  }

  self.$onInit = function() {
    self.entityAnexo['solicitacao'] = self.entity.solicitacao;
    // Array criado para identificar cada caso de uso encontra-se dentro do componente de Anexo
    self.entityAnexo['casodeuso'] = 'ALTERACOESENDERECOS';
    MeurhAcompanhamentoService.constructors.solicitacao = self.entity.solicitacao;
    MeurhAcompanhamentoService.loadParams.finished = false;
    MeurhAcompanhamentoService.loadParams.to_load = 3;
    MeurhAcompanhamentoService.load();
    entityService.get($stateParams['solicitacao']);

    self.resetTiposDocumentosRequeridosService();
    self.tiposdocumentosrequeridos = TiposDocumentosRequeridosService.load();

    $scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
        self.tiposdocumentosrequeridos = self.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
            tipodocumentorequerido.validateResult = false;
            tipodocumentorequerido.fileSelecionado = false;
            return tipodocumentorequerido;
        });
    });
  }

  self.resetTiposDocumentosRequeridosService = function() {
      TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': 5 };
      TiposDocumentosRequeridosService.loadParams = {
          to_load: 3,
          busy: false,
          finished: false
      };
      TiposDocumentosRequeridosService.after = {};
      TiposDocumentosRequeridosService.entities = [];
  }

  $rootScope.$on('meurh_solicitacoesalteracoesenderecos_loaded', function (event, args) {
    self.trabalhador = args.trabalhador.trabalhador;
  });

  $scope.$watch("mrh_slctcsltrcsndrc_frm_cntrllr.entity", function (newValue, oldValue) {
      if (newValue != oldValue) {
          self.form.$setDirty();
      }
  }, true);

  self.setEditAnexo = function (value) {
    self.editanexo = value;
  }

  self.goToShow = function () {
    self.busy = true;
    $state.go('meurh_solicitacoesalteracoesenderecos_show',
        angular.extend({}, {
            'solicitacao': $stateParams['solicitacao'],
            'estabelecimento': $stateParams['estabelecimento']
        })
    );
  }

  self.submit = function () {
    let podeSalvar = this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
        return tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio || tipodocumentorequerido.fileSelecionado;
    });

      if (self.form.$valid && !self.entity.$$__submitting && podeSalvar) {
          self.busy = true;
          entityService.save(self.entity);
      } else {
          this.validate = true;
          NewToaster.pop({
              type: 'error',
              title: 'Alguns campos do formulário apresentam erros'
          });
      }
  };

  self.onSubmitSuccess = $scope.$on("meurh_solicitacoesalteracoesenderecos_submitted", function (event, args) {
      self.entityAnexo['solicitacaohistorico'] = args.response.data.solicitacaohistorico;
      $rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});
      self.busy = true;
      $scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
        if (self.tiposdocumentosrequeridos.every((tipodocumentorequerido) => tipodocumentorequerido.validateResult)) {

          var updateSuccess = 'A Solicitação de Alteração de Endereço foi editada com sucesso.';
          NewToaster.pop({
            type: 'success',
            title: updateSuccess
          });
          if (args.response.config.method != "PUT") {
            entityService.constructors.solicitacao = args.entity.solicitacao;
          }
          self.busy = false;
          $state.go('meurh_solicitacoesalteracoesenderecos_show', angular.extend({}, {
            'solicitacao': $stateParams['solicitacao'],
            'estabelecimento': $stateParams['estabelecimento']
            })
          );
        }
      });
  });

  self.onSubmitError = $scope.$on("meurh_solicitacoesalteracoesenderecos_submit_error", function (event, args) {
      if (args.response.status == 409) {
          if (confirm(args.response.data.message)) {
              self.entity[''] = args.response.data.entity[''];
              entityService.save(self.entity);
          }
      } else {
          if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
              if (args.response.data.message == 'Validation Failed') {
                  let message = "";
                  let children = args.response.data.errors.children;
                  for (let child in children) {
                      if (children[child].errors !== undefined) {
                          for (let error in children[child].errors) {
                              message += "<li>" + children[child].errors[error] + "</li>";
                          }
                      }
                  }
                  NewToaster.pop(
                      {
                          type: 'error',
                          title: 'Erro de Validação',
                          body: "Os seguintes itens precisam ser alterados: <ul>" + message + "</ul>",
                          bodyOutputType: "trustedHtml"
                      });
              } else {
                  NewToaster.pop(
                      {
                          type: 'error',
                          title: args.response.data.message
                      });
              }
          } else {
              NewToaster.pop(
                  {
                      type: 'error',
                      title: args.response.config.method === "PUT" ? "Ocorreu um erro ao atualizar a Solicitação de Alteração de Endereço!" : "Ocorreu um erro ao inserir a Solicitação de Alteração de Endereço!"
                  });
          }
      }

  });

  self.delete = function (force) {
      entityService.delete($stateParams['solicitacao'], force);
  };

  self.onDeleteSuccess = $scope.$on("meurh_solicitacoesalteracoesenderecos_deleted", function (event, args) {
      NewToaster.pop({
          type: 'success',
          title: 'A Solicitação de Alteração de Endereço foi excluída com sucesso!'
      });
      $state.go('meurh_solicitacoesalteracoesenderecos', angular.extend(entityService.constructors));
  });

  self.onDeleteError = $scope.$on("meurh_solicitacoesalteracoesenderecos_delete_error", function (event, args) {
      if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
          NewToaster.pop(
              {
                  type: 'error',
                  title: args.response.data.message
              });
      } else {
          NewToaster.pop(
              {
                  type: 'error',
                  title: 'Ocorreu um erro ao tentar excluir a Solicitação de Alteração de Endereço!'
              });
      }
  });
}]);