import angular from 'angular';
import { ITrabalhador } from '../Trabalhadores/models/trabalhador.model';

export class HistoricosController {
    static $inject = ['$scope', '$stateParams', 'HistoricosLancamentosService', 'HistoricosMudancastrabalhadoresService', 'HistoricosReajustesTrabalhadoresService'];

    public totais: any;
    public entities: any;
    public tipo: any;
    public ano: any;
    public mes: any;

    constructor(
        public $scope: angular.IScope,
        public $stateParams: angular.ui.IStateParamsService,
        public HistoricosLancamentos: any,
        public HistoricosMudancastrabalhadores: any,
        public HistoricosReajustesTrabalhadoresService: any
    ) {
        HistoricosLancamentos.constructors = [];
        HistoricosMudancastrabalhadores.constructors = [];
        HistoricosReajustesTrabalhadoresService.constructors = [];

        $scope.$on('historicos_lancamentos_list_finished', (event, entities) => {
            this.totais = entities.reduce((total, elem) => {
                total['descontos'] = total['descontos'] || 0;
                total['proventos'] = total['proventos'] || 0;
                if (elem.evento.tipovalor === 1) {
                    total['descontos'] = parseFloat(total['descontos']) + parseFloat(elem.valorbruto);
                } else {
                    total['proventos'] = parseFloat(total['proventos']) + parseFloat(elem.valorbruto);
                }

                return total;
            }, []);
        });
    }

    tipoChange(trabalhador: ITrabalhador) {
        this.entities = [];
        this.filtra(trabalhador);
    }

    isBusy() {
        return this.HistoricosLancamentos.loadParams.busy ||
            this.HistoricosMudancastrabalhadores.loadParams.busy ||
            this.HistoricosReajustesTrabalhadoresService.loadParams.busy;
    }

    filtra(trabalhador: ITrabalhador) {
        switch (this.tipo) {
            case 'lancamentos':
                this.filtraLancamentos(trabalhador);
                break;
            case 'funcoes':
                this.HistoricosMudancastrabalhadores.constructors['tipo'] = 2;
                this.filtraMudancastrabalhadores(trabalhador);
                break;
            case 'jornadas':
                this.HistoricosMudancastrabalhadores.constructors['tipo'] = 5;
                this.filtraMudancastrabalhadores(trabalhador);
                break;
            case 'salarios':
                this.filtraReajustestrabalhadores(trabalhador);
                break;
        }
    }

    filtraLancamentos(trabalhador: ITrabalhador) {
        this.HistoricosLancamentos.constructors['ano'] = this.ano;
        this.HistoricosLancamentos.constructors['mes'] = this.mes;
        this.HistoricosLancamentos.constructors['trabalhador'] = trabalhador;
        if (this.ano && this.mes) {
            this.entities = this.HistoricosLancamentos.search(null);
        }
    }

    filtraMudancastrabalhadores(trabalhador: ITrabalhador) {
        this.HistoricosMudancastrabalhadores.constructors['trabalhador'] = trabalhador;
        this.entities = this.HistoricosMudancastrabalhadores.search();
    }

    filtraReajustestrabalhadores(trabalhador: ITrabalhador) {
        this.HistoricosReajustesTrabalhadoresService.constructors['trabalhador'] = trabalhador;
        this.entities = this.HistoricosReajustesTrabalhadoresService.search();
    }
}

angular.module('main').controller('HistoricosController', HistoricosController);
