angular.module('fileDropzone', []).directive('fileDropzone', function() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function fileDropzoneLink(scope, element, attrs, ngModel) {
            element.on('dragover', processDragOverOrEnter);
            element.on('dragenter', processDragOverOrEnter);
            element.on('dragend', endDragOver);
            element.on('dragleave', endDragOver);
            element.on('drop', dropHandler);

            function dropHandler(angularEvent) {
                let event = angularEvent.originalEvent || angularEvent;
                let file = event.dataTransfer.files;
                event.preventDefault();
                ngModel.$setViewValue(file);
                scope.$eval(attrs.onFileDrop);
            }

            function processDragOverOrEnter(angularEvent) {
                let event = angularEvent.originalEvent || angularEvent;
                if (event) {
                    event.preventDefault();
                }
                event.dataTransfer.effectAllowed = 'copy';
                element.addClass('dragging');
                return false;
            }
    
            function endDragOver() {
                element.removeClass('dragging');
            }
        }
    }
});