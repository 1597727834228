angular.module('main').service('PermissaoService', PermissaoService);

PermissaoService.$inject = [];

/**
 * Service HabilitadoService apoia a regra de negócios estabelecida aqui em permissão
 * Nele é obtido se um determinado móduto do sistema está habilitado ou não
 * Apenas algumas áreas do sistema possuem essa configuração
 */

function PermissaoService(){
    var self = {
        temConfiguracao: function(configuracao){
          return nsjGlobals.get('configuracoes').hasOwnProperty(configuracao) && nsjGlobals.get('configuracoes')[configuracao] ? true : false;
        },
        temPermissao: function(permissao){
          if(typeof permissao === "string") {
              return nsjGlobals.get('permissoes').hasOwnProperty(permissao)  ? true : false;
          } else if (typeof permissao === "object") {
              return permissao.some(x => nsjGlobals.get('permissoes').hasOwnProperty(x));
          }
        },
        temPermissaoGrupoEmpresarial: function(permissao, grupoCodigo){
          let gruposEmpresariais = nsjGlobals.get('permissoesagrupadas').gruposempresariais;
          let grpEmpSelecionado = gruposEmpresariais.find((grpEmpresarial) => grpEmpresarial.codigo === grupoCodigo);
          if (grpEmpSelecionado) {
            return !permissao.some(perm => !grpEmpSelecionado.permissoes.hasOwnProperty(perm));
          }
          return false;
        },
        modoGestorPermissao: function(permissao){
          if(typeof permissao === "string") {
              return nsjGlobals.get('permissoes')[permissao]  ? true : false;
          } else if (typeof permissao === "object") {
              return permissao.some(x => nsjGlobals.get('permissoes')[x]);
          }
        },
        isAdmin: function(){
          return nsjGlobals.get('isAdmin');
        },
        liberadoTela: function(permissao){
          return nsjGlobals.get('habilitados').hasOwnProperty(permissao) ? nsjGlobals.get('habilitados')[permissao] : false;
        },
        soPossuiPonto: function(){
          return nsjGlobals.get('soPossuiPontoweb');
        }
    }
    return self;
}