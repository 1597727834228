angular.module('angularPrint', [])

    .factory('Excel',['$window', function($window){
        var uri='data:application/vnd.ms-excel;base64,',
            template='<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><style>table td, th{padding:10px;}</style><title>Teste</title></head><body><table class="table">{table}</table></body></html>', 

            base64=function(s){return $window.btoa(unescape(encodeURIComponent(s)));},
            format=function(s,c){return s.replace(/{(\w+)}/g,function(m,p){return c[p];})};
        return {
            tableToExcel:function(tableId, worksheetName){
                var table= document.querySelector(tableId),
                    ctx={worksheet:worksheetName,table:table.innerHTML},
                    href=uri+base64(format(template,ctx));
                    
                return href;
            }
        };
    }])

    .directive('btnPrint', [function () {
        
        return{
            restrict: 'A',
            link: function(scope, element, $attrs){
                element.on('click', function(){
                    window.print();
                });
            }
        }

    }])

    .directive('exportXls', [function () {
        
        return{
            restrict: 'A',
            controller: ['$scope', '$attrs', '$injector', 'Excel', '$timeout', function listGroupCtrl($scope, $attrs, $injector, Excel, $timeout) {
                
                var link = document.createElement('a');

                document.body.appendChild(link);
                link.setAttribute("type", "hidden");
                
                $scope.exportToExcel=function(tableId, name){ // ex: '#my-table'
                
                    var browser = window.navigator.appVersion;                
                    $scope.exportHref = Excel.tableToExcel(tableId, "ExchangeData " + new Date().getDate());


                    if ((browser.indexOf('Trident') !== -1 && browser.indexOf('rv:11') !== -1) ||
                        (browser.indexOf('MSIE 10') !== -1)) {
                        var builder = new window.MSBlobBuilder();
                        builder.append(uri + format(template, ctx));
                        var blob = builder.getBlob('data:application/vnd.ms-excel');
                        window.navigator.sSaveOrOpenBlob(blob, name);
                        
                    } else {
                        $timeout(function(){

                            link.download = name ? name : 'table.xls';
                            link.href = $scope.exportHref;
                            link.click();
                        },100);
                    }
                }

            }],
            link: function(scope, element, $attrs){
                // scope.tableId = $attrs.tableId;
                scope.tableId = $attrs.tableId;
                element.on('click', function(){
                    scope.exportToExcel(scope.tableId, $attrs.fileName);
                });

            }
        }

    }])