angular.module('main')
    .controller('SalariosLiquidosController', ['$rootScope', '$scope', 'nsjRouting', '$http', 'moment', 'NewToaster', 'Tiposcalculos', 'Relatoriosliberados', 'RelatoriosService', function(
        $rootScope, $scope, nsjRouting, $http, moment, NewToaster,Tiposcalculos, Relatoriosliberados, RelatoriosService){
        var self = this;
        self.busy = false;
        self.situacao = '00';
        self.isBusy = function(){
            return Relatoriosliberados.loadParams.busy;
        }
        self.finished = function(){
            return Relatoriosliberados.loadParams.finished;
        }
        self.tiposcalculos = Tiposcalculos.load();

        self.loadMore = function(){
            Relatoriosliberados.loadMore();
        };
        
        self.filters = {
            ano: moment().year(),
            mes: moment().month()+1,
            tipocalculo: 'Fo',
            tiporelatorioliberado: 3 // 3 - Folha de pagamento
        };

        self.mesesTradutor = {
            1: 'Janeiro', 2: 'Fevereiro', 3: 'Março',4: 'Abril', 5: 'Maio', 6: 'Junho',
            7: 'Julho', 8: 'Agosto', 9: 'Setembro',10: 'Outubro', 11: 'Novembro', 12: 'Dezembro'};

        self.filter = function(){
            Relatoriosliberados.constructors = angular.copy(self.filters);
            self.folhaconsolidada = Relatoriosliberados.reload();
        }

        self.abrirRelatorioConsolidado = function(folha){
            self.carregandoRelatorio = true;
            let filters = angular.copy(self.filters);
            filters.tipocalculo = folha.tipocalculo.tipocalculo;
            filters.id = folha.relatorioliberado;
            let callback = function(){
                self.carregandoRelatorio = false;
            }
            RelatoriosService.abrirRelatorio('relatoriosliberados_show', filters, callback, false);
        }
}]);