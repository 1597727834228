import angular from 'angular';

export const RelatoriosLiberadosListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {
        $stateProvider
        .state('relatorios_ferias', {
            url: '/relatorios/ferias?tipo',
            params: { q: { dynamic: true },  calendarioferias: true},
            template: require('html-loader!./ferias.html'),
            controller: 'AvisoFeriasFormController',
            controllerAs: 'vsfrs_frm_cntrllr'
        })
        .state('relatorios_fichacadastrallote', {
            url: '/relatorios/fichacadastrallote/?estabelecimento',
            params: { q: { dynamic: true },  status: '00'},
            template: require('html-loader!./ficha-cadastral-lote.html'),
            controller: 'TrabalhadoresListController',
            controllerAs: 'trblhdrs_cntrllr'
        })
        .state('relatorios_ponto', {
            url: '/relatorios/ponto/?estabelecimento',
            params: { q: { dynamic: true },  status: '00'},
            template: require('html-loader!./relatorio-ponto.html'),
            controller: 'TrabalhadoresListController',
            controllerAs: 'trblhdrs_cntrllr'
        });
    }
];

angular.module('main').config(RelatoriosLiberadosListRouting);
