import { NsjConfig } from './../../core/nsj-core';

angular.module('main').service('ClientSession', ClientSessionService);

ClientSessionService.$inject = ['nsjRouting', '$http', '$rootScope'];

function ClientSessionService(nsjRouting, $http, $rootScope){
    var self = {
        constructors: {},
        loadParams: {
            busy: false
        },
        entities: [],
        load: function(){
            self.entities.length = 0;
            self.busy = true;
            $http.get(nsjRouting.generate('clientsession_session', {}, true)).then(function(response){
                var data = response.data;
                $rootScope.$broadcast('meurh_clientsession_session_loaded_service', response.data);
                let keys = Object.keys(data)
                for (let i in keys){
                    self.entities[keys[i]] = data[keys[i]];
                }
                self.busy = false;
            }).catch(function(error){
                if (error.xhrStatus !== 'abort') {
                    self.loadParams.busy = false;
                }
            });
            return self.entities;
        }
    };
    return self;
}