import angular from 'angular';

export class HorariosService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public entities: any = [];
    public loaded: any = false;
    public constructors: any = {};
    public after: any = {};
    public filters: any = {};
    public loading_deferred: any = null;
    public filter: any = '';
    public loadParams: any = {
        to_load: 3,
        busy: false,
        finished: false,
    };

    constructor(
        public $http: any,
        public nsjRouting: any,
        public $rootScope: any,
        public $q: any,
    ) { }

    reload(cancelPendingRequest: boolean = true) {
        if (this.loading_deferred && cancelPendingRequest) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }

    search(filter: any) {
        if (typeof filter !== 'undefined') {
            this.filter = filter.search;
            Object.keys(this.filters).forEach((k) => { delete this.filters[k]; });
            for (let fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    let filterSplit = fil.split('.');
                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        }

        return this.reload();
    }

    _load(constructors: any, offset: any, filter: any) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: any, reject: any) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('horarios_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })
                .then((response: any) => {

                    if (constructors.descricaoHorario && response.data?.length > 0) {

                        response.data.forEach(horario => {

                            let diasDeTrabalho = this.getDiasTrabalho(horario);

                            horario['horarioDetalhado'] = this.getResumoJornadaSemanal(diasDeTrabalho);
                            horario['nomeHorarioDetalhado'] = horario.nome + ' | ' + horario['horarioDetalhado'];

                        });

                    }

                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });

        });

    }

    load() {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any) => {
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }
                    }
                    this.loadParams.finished = true;
                    this.$rootScope.$broadcast('horarios_list_finished', this.entities);
                    this.loaded = true;

                    this.loadParams.to_load--;
                })
                .catch((error: any) => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                })
                .finally(() => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }
                });
        }

        return this.entities;
    }

    loadMore() {
        this.loadParams.to_load = 3;
        this.load();
    }

    find(identifier: any) {
        for (let i in this.entities) {
            if (this.entities[i]['horario'] === identifier) {
                return this.entities[i];
            }
        }
        return null;
    }

    _save(entity: any, autosave: any) {
        let method: any, url: any;
        if (entity['horario']) {
            method = 'PUT';
            url = this.nsjRouting.generate('horarios_put', { 'id': entity['horario'] }, true);
        } else {
            method = 'POST';
            url = this.nsjRouting.generate('horarios_create', angular.extend(this.constructors), true);
        }
        if (!autosave) {
            autosave = false;
            entity['$$__submitting'] = true;
        }
        let data = angular.copy(entity);
        if (data.hasOwnProperty('$$__submitting')) {
            delete data['$$__submitting'];
        }
        this.loading_deferred = this.$q.defer();
        return this.$http({
            method: method,
            url: url,
            data: data,
            timeout: this.loading_deferred.promise
        })
            .finally((response: any) => {
                if (!autosave) {
                    entity['$$__submitting'] = false;
                }
            });
    }

    save(entity: any, autosave: any) {
        this._save(entity, autosave)
            .then((response: any) => {
                if (response.data.horario) {
                    entity['horario'] = response.data.horario;
                }
                entity['$$_saved'] = true;
                this.$rootScope.$broadcast('horarios_submitted', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            })
            .catch((response: any) => {
                this.$rootScope.$broadcast('horarios_submit_error', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            });
    }

    get(identifier: any, descricaoHorario: boolean = false) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: any, reject: any) => {
            this.$http
                .get(this.nsjRouting.generate('horarios_get', { 'id': identifier }, true), { timeout: this.loading_deferred.promise })
                .then((response: any) => {

                    if (descricaoHorario && response?.data?.horario) {

                        let diasDeTrabalho = this.getDiasTrabalho(response.data);

                        response.data['horarioDetalhado'] = this.getResumoJornadaSemanal(diasDeTrabalho);
                        response.data['nomeHorarioDetalhado'] = response.data.nome + ' | ' + response.data['horarioDetalhado'];
                    }

                    this.$rootScope.$broadcast('horarios_loaded', response.data);
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });
        });
    }

    /**
     * Recebe um horário e retorna um array com seus dias de trabalho e seus horários de entrada e saída
     * @param horario
     * @returns
     */
    private getDiasTrabalho(horario: any): Array<{dia: string, entrada: string, saida: string}> {

        let diasDeTrabalho: Array<{dia: string, entrada: string, saida: string}> = [];

        for (const propriedade in horario) {

            // para cada propriedade do objeto horario, processar apenas as jornadas
            if (propriedade.startsWith('jornada')) {

                const jornadaDia = horario[propriedade];

                if (jornadaDia?.jornada != null) {

                    // horários vem no formato 00:00:00, obtendo apenas 00:00
                    diasDeTrabalho.push({
                        dia: propriedade,
                        entrada: jornadaDia.entrada.substr(0, 5),
                        saida: jornadaDia.saida.substr(0, 5)
                    });

                }

            }
        }

        return diasDeTrabalho;

    }

    /**
     * Recebe um array com os dias de trabalho e retorna uma string da jornada semanal baseada nos dias de trabalho recebidos
     * @param diasDeTrabalho
     * @returns
     */
    private getResumoJornadaSemanal(diasDeTrabalho: Array<{dia: string, entrada: string, saida: string}>) {

        let resumoTotal: string = '';
        let resumoJornada: string = '';

        // agrupando por jornadas todos os dias que possuem mesmos horários de entrada e saída
        const jornadasSemanais: any = Object.values(diasDeTrabalho.reduce((agrupador, diaTrabalho) => {
            const { entrada, saida } = diaTrabalho;

            const chave = `${entrada}-${saida}`;

            if (!agrupador[chave]) {
              agrupador[chave] = {
                entrada,
                saida,
                dias: [],
              };
            }

            agrupador[chave].dias.push(diaTrabalho.dia);

            return agrupador;
        }, {} as Record<string, { entrada: string; saida: string; dias: string[] }>));

        // para cada jornada, montar seu resumo em uma frase
        jornadasSemanais.forEach((horarioDetalhado) => {

            let diasString: string = '';

            horarioDetalhado.dias = horarioDetalhado.dias.map(dia => {

                if (dia === 'jornadaoutros') {
                    return 'Escala';
                } else {
                    // mantendo dias da semana apenas com 3 letras iniciais, removendo string 'jornada'
                    return dia.substring(7, 10);
                }

            });

            // separando os dias por ' e ' se houverem apenas 2
            if (horarioDetalhado.dias.length === 2) {
                diasString = horarioDetalhado.dias.join(' e ');
            } else {
                // separando os dias por vírgula
                horarioDetalhado.dias.forEach(dia => {
                    diasString += dia + ', ';
                });

                // removendo a última vírgula da string
                diasString = diasString.trim().replace(/,\s*$/, '');

                if (diasString === 'seg, ter, qua, qui, sex') {
                    diasString = 'segunda à sex';
                }

            }

            // concatenando os dias com o horário de entrada e saída
            resumoJornada = diasString + ' - ' + `${horarioDetalhado.entrada} às ${horarioDetalhado.saida}`;

            // se houver mais de um tipo de jornada por semana, concatenar separador '/' após cada jornada
            if (jornadasSemanais.length > 1) {
                resumoTotal += resumoJornada + ' / ';
            }
        });

        // se houver mais de um tipo de jornada por semana, remover o último separador '/' da string
        if (jornadasSemanais.length > 1) {
            return resumoTotal.trim().replace(/ \/$/, '');
        } else {
            return resumoJornada;
        }

    }

}

angular.module('main').service('HorariosService', HorariosService);
