angular.module('cepValidator', ['ngMessages']).directive('cepValidator', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attr, ctrl) {

            function customValidator(ngModelValue) {
                if (ngModelValue.length >= 9) {
                    ctrl.$setValidity('cepIncomplet', true);
                } else {
                    ctrl.$setValidity('cepIncomplet', false);
                }
                return ngModelValue;
            }
            ctrl.$parsers.push(customValidator);
        }
    };
});