// Gerado pelo MDA

angular.module('main')
    .config(['$stateProvider', function($stateProvider, ) {

        var resolve = {
            'entity': ['Motivosrescisoes', '$stateParams', '$state', '$q', function(entityService, $stateParams, $state, $q) {
                if ($stateParams['entity']) {
                    return $stateParams['entity'];
                } else {
                    if ($stateParams['motivorescisao']) {
                        return $q(function(resolve, reject) {
                            entityService.get($stateParams['motivorescisao'])
                                .then(function(data) {
                                    resolve(data);
                                })
                                .catch(function(error) {
                                    if (error.status === 404) {
                                        if ($state.href('motivosrescisoes_not_found', $stateParams)) {
                                            $state.go('motivosrescisoes_not_found', $stateParams);
                                        } else {
                                            $state.go('not_found', $stateParams);
                                        }
                                    }
                                });
                        });
                    } else {
                        return {};
                    }
                }
            }]
        };

        $stateProvider
            .state('motivosrescisoes', {
                url: "/motivosrescisoes?q",
                template: require('html-loader!./index.html'),
                controller: 'MotivosrescisoesListController',
                controllerAs: 'mtvsrscss_lst_cntrllr',
                reloadOnSearch: false
            })
    }]);