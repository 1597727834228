angular.module('main')
        .controller('FolhaPagamentoController', [
            'nsjRouting', '$scope', '$rootScope', '$stateParams', '$http', 'NewToaster', 'Tiposcalculos', 'RelatoriosFolha', '$stateParams', '$http', '$uibModal','$state', function (
                nsjRouting, $scope, $rootScope, $stateParams, $http, NewToaster, Tiposcalculos, RelatoriosFolha,$stateParams, $http, $uibModal,$state) {
            if (!$rootScope.temPermissao('relatorios_folhapagamento')) {
                $state.go('dp',$stateParams);
            }
            var self = this;
            self.busy = false;
            self.checkboxPreenchidos = [];
            self.constructors = RelatoriosFolha.constructors = [];

            self.filters = {
                ano: moment().year(),
                mes: moment().month()+1
            };
            self.isBusy = function(){
                return RelatoriosFolha.loadParams.busy;
            }
            self.finished = function(){
                return RelatoriosFolha.loadParams.finished;
            }
            self.tiposcalculos = Tiposcalculos.load();

            $rootScope.$on("meurh_folhaspagamentos_list_finished", function (event, args) {
                self.checkboxPreenchidos = [];
            });

            self.loadMore = function(){
                RelatoriosFolha.loadMore();
            };

            self.mesesTradutor = {
                1: 'Janeiro', 2: 'Fevereiro', 3: 'Março',4: 'Abril', 5: 'Maio', 6: 'Junho',
                7: 'Julho', 8: 'Agosto', 9: 'Setembro',10: 'Outubro', 11: 'Novembro', 12: 'Dezembro'};

            self.filter = function(){
                var checktoggle = document.getElementById('check_all');
                checktoggle.checked = false;
                RelatoriosFolha.constructors = angular.copy(self.filters);
                if(RelatoriosFolha.constructors.mes == ""){
                    delete RelatoriosFolha.constructors.mes;
                }
                if(RelatoriosFolha.constructors.calculos == ""){
                    delete RelatoriosFolha.constructors.calculos;
                }
                self.folhaspagamentos = RelatoriosFolha.reload();
            }

            self.checar_elemento = function(entity) {
                var removeIndex = self.checkboxPreenchidos.map(function(item) { return item.folhapagamento; }).indexOf(entity.folhapagamento);

                if(removeIndex > -1) {
                    self.checkboxPreenchidos.splice(removeIndex, 1);
                } else {
                    self.checkboxPreenchidos.push(entity);
                }
            }


            self.checar_todos = function() {
                var checktoggle = document.getElementById('check_all');              
                var elementos = document.getElementsByClassName('elementos_check');
                
                if(checktoggle.checked) {
                    self.checkboxPreenchidos = angular.copy(self.folhaspagamentos);
                    for(var i= 0; i < elementos.length; i++) {
                        elementos[i].checked = true;
                    }
                } else {
                    self.checkboxPreenchidos = [];
                    for(var i= 0; i < elementos.length; i++) {
                        elementos[i].checked = false;
                    }
                }
            }

            self.download_lote = function() {
                    
                var itens = self.checkboxPreenchidos.map(function(entity) {
                    item = { 'folhapagamento': entity.folhapagamento }
                    return item;
                });

                self.isDownloading = true;

                $http({
                    method: 'post',
                    url: nsjRouting.generate('meurh_folhaspagamentos_downloadfolhaslote', angular.extend({}, this.constructors, {}), true),
                    data: { 'entities': itens },
                    responseType: 'blob'
                }).then(function (response) {                        
                    var nomeArquivo = '';
                    var blob = response.data;
                    var fileURL = (window.URL || window.webkitURL).createObjectURL(blob);
                    var a = document.createElement("a");
                    nomeArquivo = "folhaspagamentos_lote"
                    nomeArquivo += ".zip";
                    
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = fileURL;
                    a.download = nomeArquivo;
                    a.click();
                    window.URL.revokeObjectURL(fileURL);

                    self.checkboxPreenchidos = [];
                    document.getElementById('check_all').checked = false;
                    self.isDownloading = false;
                });
            }

            self.abrirFolha = function(folha){
                self.carregandoRelatorio = true;
                let filters = angular.copy(self.filters);
                filters.id = folha.folhapagamento;
                filters.caminhodocumento = folha.caminhodocumento; 
                let callback = function(){
                    self.carregandoRelatorio = false;
                }
                
                self.busy = true;

                $http.get(
                    nsjRouting.generate('meurh_folhaspagamentos_get_pdf',
                        angular.extend(
                            {
                                'id' : folha.folhapagamento 
                            },
                            this.constructors,
                            {
                                'offset': 0,
                                'filter': self.filter,
                            }
                        ), 
                    true),
                { responseType: 'blob' })
                .then(function(response){
                    var file = new Blob([response.data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);

                    var uibModalInst = $uibModal.open({
                        animation: true,
                        keyboard: true,
                        backdrop: true,
                        template:
                            `<a id="closeModal" class="pull-right" style="margin-top: -30px;color: lightgray;cursor: pointer;" ng-click="ctrlModal.close()">
                                <i class="fas fa-times"></i>
                            </a>` +
                            '<embed src="' +
                            fileURL +
                            '" width="100%" height="100%" ng-init="ctrlModal.setStyle()"/>',

                        controller: function () {
                            selfModal = this;
                            selfModal.setStyle = function () {
                                (document.getElementsByClassName('modal-dialog')[0]).style.width =
                                    '90%';
                                (document.getElementsByClassName('modal-dialog')[0]).style.height =
                                    '90%';
                                (document.getElementsByClassName('modal-content')[0]).style.padding =
                                    '40px 20px 20px 20px';
                                (document.getElementsByClassName('modal-content')[0]).style.height =
                                    '100%';
                            }

                            selfModal.close = function() {
                                uibModalInst.close(false)
                            }
                        },
                        controllerAs: 'ctrlModal'
                    })

                    uibModalInst.result.then(
                        function() {
                            uibModalInst = undefined
                        },
                        function() {
                            uibModalInst = undefined
                        }
                    )
                })
                .catch(function(error){
                    NewToaster.pop({
                        type: 'error',
                        title: error.data.message
                    });
                }).finally(function(){
                    if(typeof callback === "function") {
                        callback();
                    }
                    self.busy = false;
                });
            }
}]);
