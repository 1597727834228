angular.module('main').directive('maxValue', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attr, ctrl) {

            function customValidator(ngModelValue) {
                let maxValue = attr.maxValue;
                if(ngModelValue <= maxValue){
                    ctrl.$setValidity('max-value', true);
                } else {
                    ctrl.$setValidity('max-value', false);
                }
                return ngModelValue;
            }
            ctrl.$parsers.push(customValidator);
        }
    };
});