angular.module('main')
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('relatorios_salariosliquidos', {
                url: "/relatorios/salarios-liquidos",
                template: require('html-loader!./salarios-liquidos.html'),
                controller: 'SalariosLiquidosController',
                controllerAs: 'salariosliquidos_ctrl'
            })

    }])

