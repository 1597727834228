angular.module('fileInput', []).directive("fileInput", function() {
    return {
      require: "ngModel",
      link: function postLink(scope, elem, attrs, ngModel) {
        elem.on("change", function(e) {
          let files = elem[0].files;
          if(files.length > 0) { 
            ngModel.$setViewValue(files); 
          } else { 
            ngModel.$setViewValue(undefined); 
          }
        })
      }
    }
});
