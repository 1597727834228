import angular from 'angular';

export class HistoricosReajustesSindicatosService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public entities: any = [];
    public loaded: any = false;
    public constructors: any = {};
    public after: any = {};
    public filters: any = {};
    public loadParams: any = {
        to_load: 3,
        busy: false,
        finished: false,
    };
    public loading_deferred: any = null;
    public filter: any = '';

    constructor(
        public $http: any,
        public nsjRouting: any,
        public $rootScope: angular.IRootScopeService,
        public $q: angular.IQService
    ) { }

    reload() {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }
    search(filter: any) {
        if (typeof filter !== 'undefined') {
            this.filter = filter.search;
            Object.keys(this.filters).forEach((k) => { delete this.filters[k]; });
            for (let fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    let filterSplit = fil.split('.');
                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        }

        return this.reload();
    }
    _load(constructors: any, offset: any, filter: any) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve, reject) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('historicos_reajustessindicatos_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((response) => {
                    reject(response);
                });

        });

    }
    load() {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any) => {
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }
                    }
                    this.loadParams.finished = true;
                    this.$rootScope.$broadcast('historicos_reajustessindicatos_list_finished', this.entities);
                    this.loaded = true;

                    this.loadParams.to_load--;
                })
                .catch((error) => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                })
                .finally(() => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }
                });
        }

        return this.entities;
    }

    loadMore() {
        this.loadParams.to_load = 3;
        this.load();
    }

    find(identifier: any) {
        for (let i in this.entities) {
            if (this.entities[i]['id'] === identifier) {
                return this.entities[i];
            }
        }
        return null;
    }
    _save(entity: any, autosave: any) {
        let method, url;
        if (entity['id']) {
            method = 'PUT';
            url = this.nsjRouting.generate('historicos_reajustessindicatos_put', { 'id': entity['id'] }, true);
        } else {
            method = 'POST';
            url = this.nsjRouting.generate('historicos_reajustessindicatos_create', angular.extend(this.constructors), true);
        }
        if (!autosave) {
            autosave = false;
            entity['$$__submitting'] = true;
        }
        let data = angular.copy(entity);
        if (data.hasOwnProperty('$$__submitting')) {
            delete data['$$__submitting'];
        }
        this.loading_deferred = this.$q.defer();
        return this.$http({
            method: method,
            url: url,
            data: data,
            timeout: this.loading_deferred.promise
        })
            .finally((response) => {
                if (!autosave) {
                    entity['$$__submitting'] = false;
                }
            });
    }
    save(entity: any, autosave: any) {

        this.
            _save(entity, autosave)
            .then((response) => {
                if (response.data.id) {
                    entity['id'] = response.data.id;
                }
                entity['$$_saved'] = true;
                this.$rootScope.$broadcast('historicos_reajustessindicatos_submitted', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            })
            .catch((response) => {
                this.$rootScope.$broadcast('historicos_reajustessindicatos_submit_error', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            });
    }

    get(identifier: any) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve, reject) => {
            this.$http
                .get(this.nsjRouting.generate('historicos_reajustessindicatos_get', { 'id': identifier }, true), { timeout: this.loading_deferred.promise })
                .then((response) => {
                    this.$rootScope.$broadcast('historicos_reajustessindicatos_loaded', response.data);
                    resolve(response.data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    }
}

angular.module('main').service('HistoricosReajustesSindicatosService', HistoricosReajustesSindicatosService);
