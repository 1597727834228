angular.module('main').directive('telefone', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attr, ctrl) {

            function customValidator(ngModelValue) {
                let allowedChars = '0123456789-';
                let numbers = '0123456789';
                let minNumberCount = 8;
                let numbersCount = 0;
                var validModel = true;
                for(index in ngModelValue.split('')){
                    let char = ngModelValue[index];
                    if(!allowedChars.contains(char)){
                        validModel = false;
                    }
                    if(numbers.contains(char)){
                        numbersCount++;
                    }
                }
                if(validModel && (numbersCount >= minNumberCount || numbersCount == 0)){
                    ctrl.$setValidity('telefone', true);
                }else {
                    ctrl.$setValidity('telefone', false);
                }
                return ngModelValue;
            }
            ctrl.$parsers.push(customValidator);
        }
    };
});