angular.module('main')
        .factory('ArquivosCategorias', ['$http', 'nsjRouting', function ($http, nsjRouting) {
                var self = {
                    entities: [],
                    constructors: {},
                    load: function () {
                        $http.get(nsjRouting.generate('arquivos_documentosgedcategorias_index', self.constructors, true), {}).then(function (response) {
                            for (var i in response.data) {
                                if(response.data[i].documentosgedtipos)
                                    response.data[i].documentosgedtipos = JSON.parse(response.data[i].documentosgedtipos);
                                
                                self.entities.push(response.data[i]);
                            }
                        });
                        
                        return self.entities;
                    }
                };

                return self;
            }])
        .controller('ArquivosDocumentosgeddetalhesListController', [
            '$rootScope', '$scope', '$state', '$http', '$stateParams', 'nsjRouting', 'ArquivosDocumentosgeddetalhes', 'ArquivosCategorias', function ($rootScope, $scope, $state, $http, $stateParams, nsjRouting, ArquivosDocumentosgeddetalhes, ArquivosCategorias) {
                var self = this;
                self.after = '';
                self.busy = false;
                self.finished = false;
                self.filter = ArquivosDocumentosgeddetalhes.filter;
                self.filter['key'] = $stateParams['q'] ? $stateParams['q'] : '';
                self.filter['filterfield'] = 'all';
                self.getCategorias = ArquivosCategorias.entities = [];
                self.search = ArquivosDocumentosgeddetalhes.search;
                self.reload = ArquivosDocumentosgeddetalhes.reload;
                self.nextPage = ArquivosDocumentosgeddetalhes.load;
                self.loadMore = ArquivosDocumentosgeddetalhes.loadMore;
                self.service = ArquivosDocumentosgeddetalhes;
                self.constructors = ArquivosDocumentosgeddetalhes.constructors = [];

                for (var i in $stateParams) {
                    if (typeof $stateParams[i] != 'undefined' && typeof $stateParams[i] != 'function' && i != 'q' && i != 'documentogeddetalhe') {
                        ArquivosDocumentosgeddetalhes.constructors[i] = $stateParams[i];
                    }
                }

                self.entities = ArquivosDocumentosgeddetalhes.reload();

                self.generateRoute = function (route, params) {
                    return nsjRouting.generate(route, params, true);
                };

                self.isBusy = function () {
                    return ArquivosDocumentosgeddetalhes.busy;
                };

                self.new = function (context) {

                    var self = this;
                    self.entity = {};

                    ArquivosDocumentosgeddetalhes._save(self.entity)
                            .then(function (response) {
                                $state.go('arquivos_documentosgeddetalhes_edit', {'documentogeddetalhe': response['data']['documentogeddetalhe'], 'entity': response['data']});
                            });
                };

                $scope.$on("arquivos_documentosgeddetalhes_deleted", function (event) {
                    self.reload();
                });

                $rootScope.$on("arquivos_documentosgeddetalhes_submitted", function (event, args) {
                    if (!args.autosave) {
                        self.reload();
                    }
                });

                self.nextPage();

                self.getCategorias = ArquivosCategorias.load();

                self.filterType = function (type) {
                    ArquivosDocumentosgeddetalhes.constructors.documentogedtipo = type;
                    ArquivosDocumentosgeddetalhes.reload();
                }

                self.filter = function (obj) {
                    ArquivosDocumentosgeddetalhes.constructors = obj;
                    ArquivosDocumentosgeddetalhes.reload();
                }

                self.ordenar = function (param) {
                    self.menuOrdenacaoAtiva = param;

                    ArquivosDocumentosgeddetalhes.filter.filterfield = 'updated_at';
                    ArquivosDocumentosgeddetalhes.constructors.order = param;
                    self.entities = ArquivosDocumentosgeddetalhes.reload();
                };
                

            }])

        .controller('ArquivosDocumentosgeddetalhesFormController', [
            '$scope', '$rootScope', '$stateParams', '$state', '$document', 'ArquivosDocumentosgeddetalhes', 'entity', 'nsjRouting', 'ArquivosDocumentosged', function ($scope, $rootScope, $stateParams, $state, $document, entityService, entity, nsjRouting, ArquivosDocumentosged) {

                var self = this;
                self.submitted = false;
                self.entity = entity;
                self.constructors = {};


                self.submit = function () {
                    self.submitted = true;
                    if (self.form.$valid && !self.entity.$$__submitting) {
                        entityService.save(self.entity);
                    }
                };

                self.onSubmitSuccess = $scope.$on("arquivos_documentosgeddetalhes_submitted", function (event, args) {
                    $state.go('arquivos_documentosgeddetalhes', self.constructors);
                });

                self.onSubmitError = $scope.$on("arquivos_documentosgeddetalhes_submit_error", function (event, args) {
                    if (args.response.status == 409) {
                        if (confirm(args.response.data.message)) {
                            self.entity[''] = args.response.data.entity[''];
                            entityService.save(self.entity);
                        }
                    } else {
                        if (typeof (args.response.data.erro) !== 'undefined') {
                            alert(args.response.data.erro);
                        } else {
                            var acao = ((args.response.config.method == "PUT") ? "atualizar" : "inserir");
                            alert("Ocorreu um erro ao tentar " + acao + ".");
                        }
                    }
                });
                self.delete = function (force) {
                    entityService.delete($stateParams['documentogeddetalhe'], force);
                };

                self.onDeleteSuccess = $scope.$on("arquivos_documentosgeddetalhes_deleted", function (event, args) {
                    $state.go('arquivos_documentosgeddetalhes', angular.extend(self.constructors));
                });

                self.onDeleteError = $scope.$on("arquivos_documentosgeddetalhes_delete_error", function (event, args) {
                    if (typeof (args.response.data.erro) !== 'undefined') {
                        alert(args.response.data.erro);
                    } else {
                        alert("Ocorreu um erro ao tentar excluir.");
                    }
                });

                for (var i in $stateParams) {
                    if (i != 'entity') {
                        self.constructors[i] = $stateParams[i] ? $stateParams[i] : '';
                    }
                }

                self.refresh = function () {
                    $rootScope.$broadcast("documentogedtipo_refresh", {'documentogedcategoria': self.entity.documentogedcategoria.documentogedcategoria});
                    self.entity.documentogedtipo = null;
                }

                $rootScope.$on("documentogedtipo_refreshed", function (event, args) {
                    if (args.length == 1) {
                        self.entity.documentogedtipo = args[0];
                    }
                });

                $scope.dropzoneConfig = {
                    'options': {
                        'url': nsjRouting.generate('_uploader_upload_documentosged', {},  true),
                        'maxFiles': '1',
                        'addRemoveLinks': true,
                        'dictDefaultMessage': 'Solte o arquivo aqui ou clique para adicionar',
                        'dictRemoveFile': 'Remover',
                        'dictCancelUpload': 'Cancelar envio',
                        'dictMaxFilesExceeded': 'Você não pode enviar mais arquivos'
                    },
                    'eventHandlers': {
                        'removedfile': function (file, formData, xhr) {
                            var response = JSON.parse(file.xhr.response);
                            ArquivosDocumentosged.delete(response.documentoged, true);
                        },
                        'success': function (file, response) {

                            var mimetype = response.mimetype.split("/");
                            var type = (mimetype[0] === 'image') ? mimetype[0] : mimetype[1];

                            self.entity.documentoged = [{
                                    documentoged: response.documentoged,
                                    error: false,
                                    file: null,
                                    going: false,
                                    nome: file.name,
                                    perc: 100,
                                    tipo: type,
                                    url: response.url
                                }];

                        }
                    }
                };


            }]);
