// Gerado pelo MDA

angular.module('main')
    .config(['$stateProvider', function($stateProvider, ) {

        var resolve = {
            'entity': ['MeurhSolicitacoessalariossobdemanda', '$stateParams', '$state', '$q', function(entityService, $stateParams, $state, $q) {
                if ($stateParams['entity']) {
                    return $stateParams['entity'];
                } else {
                    if ($stateParams['solicitacao']) {
                        return $q(function(resolve, reject) {
                            entityService.get($stateParams['solicitacao'])
                                .then(function(data) {
                                    resolve(data);
                                })
                                .catch(function(error) {
                                    if (error.status === 404) {
                                        if ($state.href('meurh_solicitacoessalariossobdemanda_not_found', $stateParams)) {
                                            $state.go('meurh_solicitacoessalariossobdemanda_not_found', $stateParams);
                                        } else {
                                            $state.go('not_found', $stateParams);
                                        }
                                    }
                                });
                        });
                    } else {
                        return {};
                    }
                }
            }]
        };

        $stateProvider
            .state('meurh_solicitacoessalariossobdemanda', {
                url: "/meurh/solicitacoessalariossobdemanda?q?situacao?created_at?trabalhador?estabelecimento",
                template: require('html-loader!./index.html'),
                controller: 'Meurh\SolicitacoessalariossobdemandaListController',
                controllerAs: 'mrh_slctcsslrssbdmnd_lst_cntrllr',
                reloadOnSearch: false
            })
    }]);