angular.module('main').service('HabilitadoService', HabilitadoService);

HabilitadoService.$inject = [];

/**
 * Função que verifica se o modulo está habilitado
 */
function HabilitadoService(){
    var self = {
        estaHabilitado: function(habilitado){
            if(typeof habilitado === "string"){
                return nsjGlobals.get('habilitados')[habilitado] ? true : false;
            } else if (typeof habilitado === "object"){
                return habilitado.some(x => nsjGlobals.get('habilitados')[x]);
            }
        }
    }
    return self;
}