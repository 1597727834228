
angular.module('main')
    .controller('MeurhSolicitacoesalteracoesenderecosFormShowController', [
        'ModalConfirmService',
        '$scope', '$stateParams', '$state', 'MeurhSolicitacoesalteracoesenderecos', 'entity', 'NewToaster', '$rootScope', 'MeurhAcompanhamentoService', 'WorkflowService', 'MeurhSolicitacoes', 'TiposDocumentosRequeridosService', function (ModalConfirmService,
            $scope, $stateParams, $state, entityService, entity, NewToaster, $rootScope, MeurhAcompanhamentoService, WorkflowService, MeurhSolicitacoes, TiposDocumentosRequeridosService) {

            var self = this;
            self.entity = entity;
            self.action = 'show';
            self.reload = entityService.reload;
            self.edit = false;
            self.editanexo = false;
            self.adicionarAnexo = false;
            self.LABELS = {
              '0': 'label-warning',
              '1': 'label-success',
              '2': 'label-danger'
            };

            self.SITUACOES = {
              '0': 'Aberta',
              '1': 'Efetuada',
              '2': 'Cancelada',
              '4': 'Recusada',
            };

            self.form = {};
            self.formAnexo = {};
            self.entityAnexo = {};
            self.actionAnexo = 'insert';
            self.constructors = [];
            self.entityAnexoLista = [];
            self.mimeType;
            
            self.$onInit = function() {
              self.entityAnexo['solicitacao'] = self.entity.solicitacao;
              self.entityAnexo['casodeuso'] = 'ALTERACOESENDERECOS';
              MeurhAcompanhamentoService.constructors.solicitacao = self.entity.solicitacao;
              MeurhAcompanhamentoService.loadParams.finished = false;
              MeurhAcompanhamentoService.loadParams.to_load = 3;
              MeurhAcompanhamentoService.load();
              entityService.get($stateParams['solicitacao']);

              $scope.$on('workflow_reloaded_after', (event, args) => {
                  $state.reload();
              });

              WorkflowService.config(this.entity.solicitacao, 'meurh_solicitacoesalteracoesenderecos_');

              self.resetTiposDocumentosRequeridosService();
              self.tiposdocumentosrequeridos = TiposDocumentosRequeridosService.load();

              $scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
                self.tiposdocumentosrequeridos = self.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
                  tipodocumentorequerido.validateResult = false;
                  tipodocumentorequerido.fileSelecionado = false;
                  tipodocumentorequerido.file = '';
                  return tipodocumentorequerido;
                });
              });
            }

            self.resetTiposDocumentosRequeridosService = function() {
                TiposDocumentosRequeridosService.entities = [];
                TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': 5 };
                TiposDocumentosRequeridosService.loadParams = {
                    to_load: 3,
                    busy: false,
                    finished: false
                };
                TiposDocumentosRequeridosService.after = {};
            }

            self.goToEdit = function () {
              self.busy = true;
              $state.go('meurh_solicitacoesalteracoesenderecos_edit', 
                  angular.extend({}, { 
                      'solicitacao': self.entity.solicitacao,
                      'estabelecimento': $stateParams['estabelecimento']
                  })
              );
            }

            $rootScope.$on('meurh_solicitacoesalteracoesenderecos_loaded', function (event, args) {
              self.trabalhador = args.trabalhador.trabalhador;
            });

            self.delete = function (force) {
                entityService.delete($stateParams['solicitacao'], force);
            };

            self.onDeleteSuccess = $scope.$on("meurh_solicitacoesalteracoesenderecos_deleted", function (event, args) {
                NewToaster.pop({
                    type: 'success',
                    title: 'A Solicitação de Alteração de Endereço foi excluída com sucesso!'
                });
                $state.go('meurh_solicitacoesalteracoesenderecos', angular.extend(entityService.constructors));
            });

            self.onDeleteError = $scope.$on("meurh_solicitacoesalteracoesenderecos_delete_error", function (event, args) {
                if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                    NewToaster.pop(
                        {
                            type: 'error',
                            title: args.response.data.message
                        });
                } else {
                    NewToaster.pop(
                        {
                            type: 'error',
                            title: 'Ocorreu um erro ao tentar excluir a Solicitação de Alteração de Endereço!'
                        });
                }
            });

            self.getLabels = function (situacao) {
              return self.LABELS[situacao];
            }
      
            self.getSituacao = function (situacao) {
              return self.SITUACOES[situacao];
            }
      
            self.setEdit = function (value) {
              self.edit = value;
            }

            self.submit = function () {
                if (self.form.$valid && !self.entity.$$__submitting) {
                    entityService.save(self.entity);
                } else {
                    NewToaster.pop({
                        type: 'error',
                        title: 'Alguns campos do formulário apresentam erros'
                    });
                }
            }

            self.onSubmitSuccess = $scope.$on("meurh_solicitacoesalteracoesenderecos_submitted", function (event, args) {  
              self.setEdit(false);
            });

            self.cancelar = function (entity) {
                var confirm = ModalConfirmService.open(entity, 'Solicitação de Alteração de Endereço');
                confirm.result.then(function (entity) {
                    self.busy = true;
                    MeurhSolicitacoes.cancelar(entity)
                        .then(function (response) {
                            NewToaster.pop({
                                type: 'success',
                                title: 'A Solicitação de Alteração de Endereço foi cancelada com sucesso'
                            });
                            self.reload();
                            $state.go('meurh_solicitacoesalteracoesenderecos', entityService.constructors);
                        }).catch(function (response) {
                            if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                                NewToaster.pop(
                                    {
                                        type: 'error',
                                        title: response.data.message
                                    });
                            } else {
                                NewToaster.pop({
                                    type: 'error',
                                    title: 'Erro ao cancelar.'
                                });
                            }
                        }).finally(function (response) {
                            self.busy = false;
                        });
                })
                    .catch(function (error) {
                        if (error != 'backdrop click' && error != 'cancelar' && error != 'escape key press') {
                            NewToaster.pop({
                                type: 'error',
                                title: error
                            });
                        }
                    });
            };
        }]);
