angular.module('main')

.controller('ModalInstanceCtrl', function ($uibModalInstance) {
    var self = this;
    self.ok = function () {
        $uibModalInstance.close();
    };
    self.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
})