
angular.module('main')
.controller('MeurhSolicitacoessalariossobdemandaListController', [
    '$rootScope', '$scope', '$state', '$http', '$stateParams', '$location', '$transitions', 'nsjRouting', 'NewToaster', 'MeurhSolicitacoessalariossobdemanda', '$uibModal',
    function ($rootScope, $scope, $state, $http, $stateParams, $location, $transitions, nsjRouting, NewToaster, entityService, $uibModal) {

        var self = this;
        self.entityModal;
        self.busy = false;
        self.service = entityService;
        entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        self.filters = entityService.filters = {};

        // SOBRESCRITO: ARRAY PARA FILTRO
        self.optionsSituacao = {'Todos':{texto:"Todos",valor:null},'Em análise' :{texto:'Em análise',valor:0}, 'Aprovada':{texto:'Aprovada',valor:1}, 'Reprovada':{texto:'Reprovada',valor:2}, 'Executada': {texto:'Executada',valor:4}};

        self.search = function (filter) {
            if (filter.search != '') {
                $location.path($location.path()).search(angular.extend({}, filter.filters, { 'q': filter.search }));
            } else {
                $location.path($location.path()).search(angular.extend({}, filter.filters));
            }
            return entityService.search(filter);
        };
        self.reload = entityService.reload;
        self.loadMore = function () {
            entityService.loadMore()
        };
        self.constructors = entityService.constructors = [];
        self.options = { 'situacao': 'Situação', 'created_at1': 'Data de Início', 'created_at2': 'Data de Fim', 'trabalhador.nome': 'Nome do Trabalhador', };
        self.maxoccurs = {};
        self.selects = {
            'situacao': { '0': 'Em análise', '1': 'Aprovada', '2': 'Reprovada', '4': 'Executada',},
        };
        for (var i in $stateParams) {
            if (['situacao', 'created_at1', 'created_at2', 'trabalhador',].indexOf(i) > -1 && $stateParams[i] !== undefined) {
                entityService.filters[i] = $stateParams[i];
            }
            else if (typeof $stateParams[i] != 'undefined' && typeof $stateParams[i] != 'function' && i != 'q' && i != 'solicitacao') {
                entityService.constructors[i] = $stateParams[i];
            }
        }

        self.reprovar = function(entity){
            entityService.reprovar(entity).then(function(response){
                NewToaster.pop({
                        type: 'success',
                        title : 'Sucesso ao reprovar!'
                    });
                self.reload();
                $state.go('solicitacoessalariossobdemanda', angular.extend(entityService.constructors));
            }).catch(function(response){
                if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                    NewToaster.pop(
                    {
                        type: 'error',
                        title: response.data.message
                    });
                } else {
                    NewToaster.pop({
                        type: 'error',
                        title: 'Erro ao reprovar.'
                    });
                }
            }).finally(function(response){
                self.busy = false;
            });
        };

        self.aprovar = function(entity){
            entityService.aprovar(entity).then(function(response){
                NewToaster.pop({
                        type: 'success',
                        title : 'Sucesso ao aprovar!'
                    });
                self.reload();
                $state.go('solicitacoessalariossobdemanda', angular.extend(entityService.constructors));
            }).catch(function(response){
                if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                    NewToaster.pop(
                    {
                        type: 'error',
                        title: response.data.message
                    });
                } else {
                    NewToaster.pop({
                        type: 'error',
                        title: 'Erro ao aprovar.'
                    });
                }
            }).finally(function(response){
                self.busy = false;
            });
        };

        self.aprovareExecutar = function(entity){
            entityService.aprovareExecutar(entity).then(function(response){
                NewToaster.pop({
                        type: 'success',
                        title : 'Sucesso ao aprovar e executar!'
                    });
                self.reload();
                $state.go('solicitacoessalariossobdemanda', angular.extend(entityService.constructors));
            }).catch(function(response){
                if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                    NewToaster.pop(
                    {
                        type: 'error',
                        title: response.data.message
                    });
                } else {
                    NewToaster.pop({
                        type: 'error',
                        title: 'Erro ao aprovar e executar.'
                    });
                }
            }).finally(function(response){
                self.busy = false;
            });
        };

        self.executar = function(entity){
            entityService.executar(entity).then(function(response){
                NewToaster.pop({
                        type: 'success',
                        title : 'Sucesso ao executar!'
                    });
                self.reload();
                $state.go('solicitacoessalariossobdemanda', angular.extend(entityService.constructors));
            }).catch(function(response){
                if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                    NewToaster.pop(
                    {
                        type: 'error',
                        title: response.data.message
                    });
                } else {
                    NewToaster.pop({
                        type: 'error',
                        title: 'Erro ao executar.'
                    });
                }
            }).finally(function(response){
                self.busy = false;
            });
        };

        self.showModal = function(entity) {
            self.entityModal = entity;
            let uibModalInst = $uibModal.open({
                animation: true,
                keyboard: true,
                backdrop: true,
                template: require('html-loader!./analisar.html'),

                controller: function () {
                    selfModal = this;
                    selfModal.entity = self.entityModal;

                    selfModal.close = function () {
                        uibModalInst.close(false)
                    }

                    selfModal.aprovar = function () {
                        uibModalInst.close(false)
                        self.aprovar(self.entityModal);
                    }

                    selfModal.reprovar = function () {
                        uibModalInst.close(false)
                        self.reprovar(self.entityModal);
                    }

                    selfModal.executar = function () {
                        uibModalInst.close(false)
                        self.executar(self.entityModal);
                    }

                    selfModal.aprovareExecutar = function () {
                        uibModalInst.close(false)
                        self.aprovareExecutar(self.entityModal);
                    }
                },
                controllerAs: 'ctrlModal'
            })

            uibModalInst.result.then(
                function() {
                    uibModalInst = undefined
                },
                function() {
                    uibModalInst = undefined
                }
            )
        }


        

        self.entities = entityService.reload();

        self.fields = [
            {
                value: 'trabalhador.nome',
                label: 'Trabalhador',
                type: 'string',
                style: 'title',
                copy: '',
            },

            {
                value: 'created_at',
                label: 'Data de Solicitação',
                type: 'string',
                style: 'title',
                copy: '',
            },
    
            {
                value: 'valor',
                label: 'Atencipação Solicitada',
                type: 'moeda',
                style: 'default',
                copy: '',
            },
            // SOBRESCRITO: INSERE COLUNA DE DADOS BANCARIOS
            {
                value: 'dados_bancarios',
                label: 'Conta de Destino',
                type: 'string',
                style: 'default',
                copy: '',
            },
    
            {
                value: 'situacao',
                label: 'Situação',
                type: 'options',
                style: 'label',
                copy: '',
                options: { 'Em análise': 'entity.situacao == "0"', 'Aprovada': 'entity.situacao == "1"', 'Reprovada': 'entity.situacao == "2"','Executada': 'entity.situacao == "4"'},
                label_class: '{ "label-warning": entity.situacao === 0, "label-success": entity.situacao === 1, "label-danger": entity.situacao === 2,"label-default": entity.situacao === 4}',
            }

        ];


        self.generateRoute = function (route, params) {
            return nsjRouting.generate(route, params, true);
        };

        self.isBusy = function () {
            return entityService.loadParams.busy;
        };

        $scope.$on("meurh_solicitacoessalariossobdemanda_deleted", function (event) {
            self.reload();
        });


        $scope.$on("$destroy", function () {
            if (entityService.loading_deferred) {
                entityService.loading_deferred.resolve();
            }
        });

        $rootScope.$on("meurh_solicitacoessalariossobdemanda_submitted", function (event, args) {
            if (!args.autosave) {
                self.reload();
            }
        });

        // SOBRESCRITO: Preparando dados para a table
        $rootScope.$on('meurh_solicitacoessalariossobdemanda_list_finished', function(event, args) {
            for (var i = 0; i < self.entities.length; i++) {
                self.entities[i]['dados_bancarios'] = self.entities[i]["trabalhador"]["dados_agencia"]["banco"]["nome"];
                self.entities[i]['dados_bancarios']+= " - A " + self.entities[i]["trabalhador"]["dados_agencia"]["numero"];
                self.entities[i]['dados_bancarios']+= " | CC " + self.entities[i]["trabalhador"]["numerocontasalario"];
                self.entities[i]['dados_bancarios']+= "-" + self.entities[i]["trabalhador"]["numerocontasalariodv"];

                self.entities[i].created_at = moment(self.entities[i].created_at, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY HH:mm");
            }
        });

    }]);
