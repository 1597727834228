angular.module('main').directive('year', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ctrl) {

            function customValidator(ngModelValue) {
                let minYear = attrs.minYear;
                let maxYear = new Date().getFullYear() + 1;
                if (ngModelValue < minYear) {
                    ctrl.$setValidity('year', false);
                    return 0;
                } else if (ngModelValue > maxYear) {
                    ctrl.$setValidity('year', false);
                } else if (ngModelValue <= maxYear && ngModelValue >= minYear) {
                    ctrl.$setValidity('year', true);
                }
                return ngModelValue;
            }
            ctrl.$parsers.push(customValidator);
        }
    };
});