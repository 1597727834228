angular.module('main').directive('vtadicionalConteudo', ['$compile', function ($compile) {
    return {
        restrict: 'E',
        require: 'ngModel',
        link: function (scope, element, attr, ctrl) {

            scope.$watch(attr.tipoConteudo, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    angular.element(element[0].querySelector('.vtadicional-conteudo')).remove();
                    compilarInput();
                }
            }, true)

            let compilarInput = function () {
                let tipoconteudo = scope.$eval(attr.tipoConteudo);
                let typeHtml, attrsAdicionaisHtml;

                if (tipoconteudo === 0) {
                    typeHtml = 'type="number"';
                    attrsAdicionaisHtml = ' min="0" max="9999" disable-negative-number';
                } else if (tipoconteudo === 1) {
                    typeHtml = 'type="text"';
                    attrsAdicionaisHtml = ' ui-money-mask maxlength="20"';
                }

                let input = '<input id="conteudo" name="conteudo" class="form-control vtadicional-conteudo"'
                input += ' ng-model="' + attr.ngModel + '"';
                input += ' ng-disabled="' + attr.ngDisabled + '"';
                input += ' ng-required="' + attr.ngRequired + '"';
                input += typeHtml;
                input += attrsAdicionaisHtml;
                input += ' />';

                let elmt = angular.element(input);
                element.append(elmt);
                $compile(elmt)(scope);
            }
            compilarInput();
        }
    };
}]);