angular.module('main').service('NovidadesService', NovidadesService)

NovidadesService.$inject = [];

function NovidadesService() {
  var self = {
    dados: [
      {
        id: 1,
        data: 'Janeiro de 2022',
        sections: [
          {
            title: 'Créditos e descontos',
            desc: 'Nesta tela é possível criar solicitações de créditos e descontos, assim como também é possível visualizar as já criadas.',
          },
          {
            title: 'Folha de ponto',
            desc: 'A tela de folha de ponto consta todos os registros de horários de cada colaborador da empresa, podendo visualizar a folha de ponto e também realizar o seu download.',
          }
        ],
      },
      {
        id: 2,
        data: 'Dezembro de 2021',
        sections: [
          {
            title: 'Férias',
            desc: 'Para a marcação das férias o usuário vai informar o início do gozo, a quantidade de dias de gozo, se haverá ou não abono pecuniário e, caso haja, quantos dias serão vendidos e se haverá adiantamento de 13º salário.',
          },
        ]
      },
    ]
  };
  return self;
}