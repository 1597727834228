
angular.module('main')
  .controller('MeurhSolicitacoesalteracoesenderecosFormNewController', [
    '$scope', '$stateParams', '$state', 'MeurhSolicitacoesalteracoesenderecos', 'entity', 'NewToaster', '$stateParams', '$rootScope', 'MeurhAnexosService', 'MeurhVisualizarmodalService', '$transitions', 'TiposDocumentosRequeridosService', function (
      $scope, $stateParams, $state, entityService, entity, NewToaster, $stateParams, $rootScope, MeurhAnexosService, MeurhVisualizarmodalService, $transitions, TiposDocumentosRequeridosService) {

      var self = this;
      self.entity = entity;

      self.action = 'insert';
      self.tipo = $stateParams["tipo"];
      self.entity.situacao = '';
      self.entityAnexo = {};
      ascunhoSendoCriado = false;
      self.form = {};
      self.constructors = [];
      self.formAnexo = {};
      self.editanexo = true;
      self.actionAnexo = 'insert';
      self.entityAnexoLista = [];
      self.mimeType;
      self.adicionarAnexo = false;
      self.filelist = [];
      this.tiposdocumentosrequeridos = [];

      self.$onInit = function () {
        self.criaRascunho();
        $rootScope.codigoHook = self.entity.codigo;

        self.resetTiposDocumentosRequeridosService();
        self.tiposdocumentosrequeridos = TiposDocumentosRequeridosService.load();

        $scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
          self.tiposdocumentosrequeridos = self.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
            tipodocumentorequerido.validateResult = false;
            tipodocumentorequerido.fileSelecionado = false;
            return tipodocumentorequerido;
          });
        });
      }

      self.resetTiposDocumentosRequeridosService = function() {
        self.tiposdocumentosrequeridos = [];
        TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': 5 };
        TiposDocumentosRequeridosService.entities = [];
        TiposDocumentosRequeridosService.loadParams = {
          to_load: 3,
          busy: false,
          finished: false
        };
        TiposDocumentosRequeridosService.after = {};
      }
    

      self.submit = function (rascunho) {
        if (rascunho) {
          entityService.constructors.situacao = -1;
          entityService.save(self.entity);  
        } else {
          var podeSalvar = this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
            return tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio || tipodocumentorequerido.fileSelecionado;
          });

          if (self.form.$valid && !self.entity.$$__submitting && podeSalvar) {
            self.entity['solicitacao'] = self.entityAnexo['solicitacao'];
            entityService.constructors.situacao = 0;
            self.entity.situacao = 0;
            entityService.save(self.entity);
          } else {

				    this.validate = true;
            NewToaster.pop({
              type: 'error',
              title: 'Alguns campos do formulário apresentam erros'
            });
          }
        }
      };

      self.criaRascunho = function () {
        self.rascunhoSendoCriado = true;
        self.entity.situacao = -1;
        self.submit(true);
      }

      self.apagaSolicitacaoRascunho = function (event) {
        if(confirm('A Solicitação de Alteração de Endereço não salva será perdida. Deseja continuar?')) {
          let docs = self.entityAnexoLista;
          if (docs.length > 0) {
            for (let i = 0; i < docs.length; i++) {
              self.removeAnexo(docs[i]);
            }
          }
          entityService.delete({ solicitacao: self.entity.solicitacao }, true);
        } else {
          event.preventDefault();
        }
      }
      
      self.limpaForm = function(){
          self.entity.bairro = '';
          self.entity.cep = '';
          self.entity.codigo = '';
          self.entity.complemento = '';
          self.entity.logradouro = '';
          delete self.entity.municipioresidencia;
          self.entity.numero = '';
          delete self.entity.paisresidencia;
          delete self.entity.tipologradouro;
      }
      
      self.setEditAnexo = function (value) {
        self.editanexo = value;
      }

      self.trustUrl = function (subentityURL) {
        let url = self.$sce.trustAsResourceUrl(subentityURL);
        return url;
      }

      self.transformUrl = function () {
        self.entity.solicitacao.forEach(function (element) {
          element.url = self.trustUrl(element.url);
        });
      }

      self.onSubmitSuccess = $scope.$on("meurh_solicitacoesalteracoesenderecos_submitted", function (event, args) {
        if (entityService.constructors.situacao === -1) {
          self.entityAnexo['solicitacao'] = self.entity.solicitacao;
          self.entityAnexo['casodeuso'] = 'ALTERACOESENDERECOS';
          entityService.constructors.id = self.entity.solicitacao;
        }
        if (self.rascunhoSendoCriado) {
          self.rascunhoSendoCriado = false;
          self.entity = args.response.data;
          $rootScope.codigoHook = self.entity.codigo;
        } else {
          NewToaster.pop({
            type: 'success',
            title: 'A Solicitação de Alteração de Endereço foi criada com sucesso!'
          });
          $rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {'filelist': self.filelist});
          entityService.constructors.solicitacao = args.entity.solicitacao;
          $state.go('meurh_solicitacoesalteracoesenderecos', entityService.constructors);
        }
        self.busy = false;
      });

      self.onSubmitError = $scope.$on("meurh_solicitacoesalteracoesenderecos_submit_error", function (event, args) {
        if (args.response.status == 409) {
          if (confirm(args.response.data.message)) {
            self.entity[''] = args.response.data.entity[''];
            entityService.save(self.entity);
          }
        } else {
          if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
            if (args.response.data.message == 'Validation Failed') {
              let message = "";
              let children = args.response.data.errors.children;
              for (let child in children) {
                if (children[child].errors !== undefined) {
                  for (let error in children[child].errors) {
                    message += "<li>" + children[child].errors[error] + "</li>";
                  }
                }
              }
              NewToaster.pop(
                {
                  type: 'error',
                  title: 'Erro de Validação',
                  body: "Os seguintes itens precisam ser alterados: <ul>" + message + "</ul>",
                  bodyOutputType: "trustedHtml"
                });
            } else {
              NewToaster.pop(
                {
                  type: 'error',
                  title: args.response.data.message
                });
            }
          } else {
            NewToaster.pop(
              {
                type: 'error',
                title: 'Ocorreu um erro ao inserir a Solicitação de Alteração de Endereço!'
              });
          }
        }
      });
    }]);
