import angular from 'angular';
import { LoadParams } from '../../shared/models/loadparams.model';
import { IFolga } from './models/folgas.model';

export class FolgasService {

    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public entities: any = [];
    public loaded: boolean = false;
    public constructors: any = {};
    public after: any = {};
    public filters: any = {};
    public loadParams: LoadParams = new LoadParams();
    public loading_deferred: any = null;
    public filter: any = '';

    constructor(
        public $http: angular.IHttpService,
        public nsjRouting: any,
        public $rootScope: angular.IRootScopeService,
        public $q: angular.IQService
    ) {
    }

    reload() {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }

    search(filter: any) {
        if (typeof filter !== 'undefined') {
            this.filter = filter.search;
            Object.keys(this.filters).forEach((k: any) => { delete this.filters[k]; });
            for (var fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    var filterSplit = fil.split('.');
                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        }

        return this.reload();
    }

    _load(constructors: any, offset: any, filter: any) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: any, reject: any) => {
            this.$http({
                    method: 'GET',
                    url: this.nsjRouting.generate('meurh_folgas_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                    timeout: this.loading_deferred.promise
                })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });

        });

    }

    load() {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any) => {
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }
                    }
                    this.loadParams.finished = true;
                    this.$rootScope.$broadcast('folgas_list_finished', this.entities);
                    this.loaded = true;

                    this.loadParams.to_load--;
                })
                .catch((error: any) => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                })
                .finally(() => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }
                });
        }

        return this.entities;
    }

    loadMore() {
        this.loadParams.to_load = 3;
        this.load();
    }

    find(identifier: any) {
        for (var i in this.entities) {
            if (this.entities[i]['folga'] === identifier) {
                return this.entities[i];
            }
        }
        return null;
    }

    _save(entity: any, autosave: any) {
        var method, url;
        if (entity['folga']) {
            method = 'PUT';
            url = this.nsjRouting.generate('meurh_folgas_put', { 'id': entity['folga'] }, true);
        } else {
            method = 'POST';
            url = this.nsjRouting.generate('meurh_folgas_create', angular.extend(this.constructors), true);
        }
        if (!autosave) {
            autosave = false;
            entity['$$__submitting'] = true;
        }
        var data = angular.copy(entity);
        if (data.hasOwnProperty('$$__submitting')) {
            delete data['$$__submitting'];
        }
        this.loading_deferred = this.$q.defer();
        return this.$http({
                method: method,
                url: url,
                data: data,
                timeout: this.loading_deferred.promise
            })
            .finally(() => {
                if (!autosave) {
                    entity['$$__submitting'] = false;
                }
            });
    }

    save(entity: any, autosave: any) {

        this._save(entity, autosave)
            .then((response: any) => {
                if (response.data.folga) {
                    entity['folga'] = response.data.folga;
                }
                entity['$$_saved'] = true;
                this.$rootScope.$broadcast('folgas_submitted', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            })
            .catch((response: any) => {
                this.$rootScope.$broadcast('folgas_submit_error', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            });
    }

    get(identifier: any): any {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: any, reject: any) => {
            this.$http
                .get(this.nsjRouting.generate('meurh_folgas_get', { 'id': identifier }, true), { timeout: this.loading_deferred.promise })
                .then((response: any) => {
                    this.$rootScope.$broadcast('folgas_loaded', response.data);
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });
        });
    }

    salvarEmLote(entity: Array<IFolga>): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();
		entity['$$__submitting'] = true;

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'POST',
				url: this.nsjRouting.generate('meurh_folgas_inserir_folga_lote', {}, true),
				data: angular.copy(entity),
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
                response.dataLength = entity.length;
				this.$rootScope.$broadcast('folgas_lote_submitted', response);
				resolve(response.data);
			})
			.catch((response: any) => {
				this.$rootScope.$broadcast('folgas_lote_submit_error', response);
				reject(response);
			}).finally(() => {
				entity['$$__submitting'] = false;
			});
		});
	}

    excluirEmLote(entity: Array<Partial<IFolga>>): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();
        entity['$$__submitting'] = true;

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'POST',
				url: this.nsjRouting.generate('meurh_folgas_exclusao_em_lote', {}, true),
				data: angular.copy(entity),
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
                response.dataLength = entity.length;
				this.$rootScope.$broadcast('folgas_lote_deleted', response);
				resolve(response.data);
			})
			.catch((response: any) => {
				this.$rootScope.$broadcast('folgas_lote_delete_error', response);
				reject(response);
			}).finally(() => {
				entity['$$__submitting'] = false;
			});
		});
	}

    loadNoPagination(params: {
        datainicio: string,
        datafim: string,
        empresa: string,
		trabalhador_estabelecimento?: string
    }): any {
        this.loading_deferred = this.$q.defer();
        this.loadParams.busy = true;

        const urlParams = {
			estabelecimento: '',
			...params
		};

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
           this.$http
                .get(this.nsjRouting.generate(
                    'meurh_folgas_indexsempaginacao', urlParams, true),
                    { timeout: this.loading_deferred.promise }
                )
                .then((response: any): void => {
                    this.$rootScope.$broadcast('folgas_sempaginacao_loaded', response.data);
                    this.loadParams.busy = false;

                    resolve(response.data);
                })
                .catch((response: any): void => {
                    this.loadParams.busy = false;
                    reject(response);
                });
        });
    }

    saveEscala(entity: any): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();
		entity['$$__submitting'] = true;

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'POST',
				url: this.nsjRouting.generate('meurh_folgas_escala', {
                    estabelecimento: ''
                }, true),
				data: angular.copy(entity),
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
                response.dataLength = entity.length;
				this.$rootScope.$broadcast('folgas_escala_submitted', response);
				resolve(response.data);
			})
			.catch((response: any) => {
				this.$rootScope.$broadcast('folgas_escala_submit_error', response);
				reject(response);
			}).finally(() => {
				entity['$$__submitting'] = false;
			});
		});
	}

    getFolgasFixas(params: {
        datainicio: string,
        datafim: string,
        empresa: string,
		trabalhador_estabelecimento?: string
    }) {
        this.loading_deferred = this.$q.defer();
        this.loadParams.busy = true;

        const urlParams = {
			estabelecimento: '',
			...params
		};

        return this.$q((resolve, reject) => {
            this.$http
                .get(this.nsjRouting.generate(
                    'meurh_folgas_folgas_fixas', urlParams, true),
                    { timeout: this.loading_deferred.promise }
                )
                .then((response) => {
                    this.$rootScope.$broadcast('folgas_fixas_loaded', response.data);
                    resolve(response);
                })
                .catch((response) => {
                    this.loadParams.busy = false;
                    reject(response);
                });
        });
    }
}

angular.module('main').service('FolgasService', FolgasService);
