const scrollIntoView = require('scroll-into-view-if-needed');
angular.module('scroll.If',[])
    .directive('scrollIf', function () {
        return function (scope, element, attributes) {
            setTimeout(function () {
                if (scope.$eval(attributes.scrollIf)) {
                   scrollIntoView.default(element[0],{block: 'end', behavior: 'smooth'});
                }
            });
        }
    })