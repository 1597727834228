// Gerado pelo MDA

angular.module('main').service('MeurhSolicitacoessalariossobdemanda', MeurhSolicitacoessalariossobdemanda);

MeurhSolicitacoessalariossobdemanda.$inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

function MeurhSolicitacoessalariossobdemanda($http, nsjRouting, $rootScope, $q) {

    var self = {
        entities: [],
        loaded: false,
        constructors: {},
        after: {},
        filters: {},
        loadParams: {
            to_load: 3,
            busy: false,
            finished: false,
        },
        loading_deferred: null,
        filter: '',
        reload: function() {
            if (self.loading_deferred) {
                self.loading_deferred.resolve();
            }

            self.loadParams.finished = false;
            self.loadParams.to_load = 3;
            self.after = {};
            self.loaded = false;
            self.entities.length = 0;
            self.loadParams.busy = false;

            return self.load();
        },
        search: function(filter) {
            if (typeof filter != 'undefined') {
                self.filter = filter.search;
                Object.keys(self.filters).forEach(function(k) { delete self.filters[k] });
                for (var fil in filter.filters) {
                    if (fil.indexOf('.') > -1) {
                        var filterSplit = fil.split('.');
                        self.filters[filterSplit[0]] = {};
                        self.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                    } else {
                        self.filters[fil] = filter.filters[fil];
                    }
                }
            }

            return self.reload();
        },
        _load: function(constructors, offset, filter) {
            self.loading_deferred = $q.defer();

            return $q(function(resolve, reject) {
                $http({
                        method: 'GET',
                        url: nsjRouting.generate('meurh_solicitacoessalariossobdemanda_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, self.filters), true),
                        timeout: self.loading_deferred.promise
                    })
                    .then(function(response) {
                        resolve(response.data);
                    })
                    .catch(function(response) {
                        reject(response);
                    });

            });

        },
        load: function() {
            if (!self.loadParams.busy && !self.loadParams.finished && self.loadParams.to_load > 0) {
                self.loadParams.busy = true;

                self._load(self.constructors, self.after, self.filter)
                    .then(function(data) {
                        if (data.length > 0) {
                            for (var i = 0; i < data.length; i++) {
                                self.entities.push(data[i]);
                            }
                            self.after['solicitacao'] = self.entities[self.entities.length - 1]['solicitacao'];
                            self.after['solicitacao'] = self.entities[self.entities.length - 1]['solicitacao'];
                        }
                        if (data.length < 20) {
                            self.loadParams.finished = true;
                            $rootScope.$broadcast("meurh_solicitacoessalariossobdemanda_list_finished", self.entities);
                        } else {
                            self.loadParams.finished = false;
                        }
                        self.loaded = true;

                        self.loadParams.to_load--;
                    })
                    .catch(function(error) {
                        if (error.xhrStatus !== 'abort') {
                            self.loadParams.finished = true;
                        }
                    })
                    .finally(function() {
                        if (self.loaded || self.loadParams.finished) {
                            self.loadParams.busy = false;
                        }
                        self.load();
                    });
            }

            return self.entities;
        },
        loadMore: function() {
            self.loadParams.to_load = 3;
            self.load();
        },
        find: function(identifier) {
            for (var i in self.entities) {
                if (self.entities[i]['solicitacao'] == identifier) {
                    return self.entities[i];
                }
            }
            return null;
        },
        _save: function(entity, autosave) {
            var method, url;
            if (entity['solicitacao']) {
                method = "PUT";
                url = nsjRouting.generate('meurh_solicitacoessalariossobdemanda_put', { 'id': entity['solicitacao'] }, true);
            } else {
                method = "POST";
                url = nsjRouting.generate('meurh_solicitacoessalariossobdemanda_create', angular.extend(self.constructors), true);
            }
            if (!autosave) {
                autosave = false;
                entity['$$__submitting'] = true;
            }
            var data = angular.copy(entity);
            if (data.hasOwnProperty('$$__submitting')) {
                delete data['$$__submitting'];
            }
            self.loading_deferred = $q.defer();
            return $http({
                    method: method,
                    url: url,
                    data: data,
                    timeout: self.loading_deferred.promise
                })
                .finally(function(response) {
                    if (!autosave) {
                        entity['$$__submitting'] = false;
                    }
                });
        },
        save: function(entity, autosave) {

            self
                ._save(entity, autosave)
                .then(function(response) {
                    if (response.data.solicitacao) {
                        entity['solicitacao'] = response.data.solicitacao;
                    }
                    entity['$$_saved'] = true;
                    $rootScope.$broadcast("meurh_solicitacoessalariossobdemanda_submitted", {
                        entity: entity,
                        response: response,
                        autosave: autosave
                    });
                })
                .catch(function(response) {
                    $rootScope.$broadcast("meurh_solicitacoessalariossobdemanda_submit_error", {
                        entity: entity,
                        response: response,
                        autosave: autosave
                    });
                });
        },
        reprovar: function(entity) {
            self.loading_deferred = $q.defer();
            entity['$$__submitting'] = true;
            return $q(function(resolve, reject) {
                $http({
                        method: 'post',
                        url: nsjRouting.generate('meurh_solicitacoessalariossobdemanda_reprovar', { 'id': entity.solicitacao }, true),
                        data: angular.copy(entity),
                        timeout: self.loading_deferred.promise
                    })
                    .then(function(response) {
                        resolve(response.data);
                    })
                    .catch(function(response) {
                        reject(response);
                    }).finally(function(response) {
                        entity['$$__submitting'] = false;
                    });
            });
        },
        aprovar: function(entity) {
            self.loading_deferred = $q.defer();
            entity['$$__submitting'] = true;
            return $q(function(resolve, reject) {
                $http({
                        method: 'post',
                        url: nsjRouting.generate('meurh_solicitacoessalariossobdemanda_aprovar', { 'id': entity.solicitacao }, true),
                        data: angular.copy(entity),
                        timeout: self.loading_deferred.promise
                    })
                    .then(function(response) {
                        resolve(response.data);
                    })
                    .catch(function(response) {
                        reject(response);
                    }).finally(function(response) {
                        entity['$$__submitting'] = false;
                    });
            });
        },
        aprovareExecutar: function(entity) {
            self.loading_deferred = $q.defer();
            entity['$$__submitting'] = true;
            return $q(function(resolve, reject) {
                $http({
                        method: 'post',
                        url: nsjRouting.generate('meurh_solicitacoessalariossobdemanda_aprovare_executar', { 'id': entity.solicitacao }, true),
                        data: angular.copy(entity),
                        timeout: self.loading_deferred.promise
                    })
                    .then(function(response) {
                        resolve(response.data);
                    })
                    .catch(function(response) {
                        reject(response);
                    }).finally(function(response) {
                        entity['$$__submitting'] = false;
                    });
            });
        },
        executar: function(entity) {
            self.loading_deferred = $q.defer();
            entity['$$__submitting'] = true;
            return $q(function(resolve, reject) {
                $http({
                        method: 'post',
                        url: nsjRouting.generate('meurh_solicitacoessalariossobdemanda_executar', { 'id': entity.solicitacao }, true),
                        data: angular.copy(entity),
                        timeout: self.loading_deferred.promise
                    })
                    .then(function(response) {
                        resolve(response.data);
                    })
                    .catch(function(response) {
                        reject(response);
                    }).finally(function(response) {
                        entity['$$__submitting'] = false;
                    });
            });
        },
        get: function(identifier) {
            self.loading_deferred = $q.defer();

            return $q(function(resolve, reject) {
                $http
                    .get(nsjRouting.generate('meurh_solicitacoessalariossobdemanda_get', { 'id': identifier }, true), { timeout: self.loading_deferred.promise })
                    .then(function(response) {
                        $rootScope.$broadcast("meurh_solicitacoessalariossobdemanda_loaded", response.data);
                        resolve(response.data);
                    })
                    .catch(function(response) {
                        reject(response);
                    });
            });
        }




    };

    return self;
}