angular.module('main')
    .service('BuscaAvancadaService', ['$uibModal', '$rootScope', '$filter', function ($uibModal, $rootScope, $filter) {
        var selfService = {
            open: function (scope, item, service, columns, values, title, enumFilters, nameVariable, semfiltro, modoselecao, modofiltro, funcaofiltro) {
                return $uibModal.open({
                    template: require('html-loader!./busca.avancada.html'),
                    controller: ['entity', '$uibModalInstance', function BuscaAvancadaModal(entity, $uibModalInstance) {
                        var selfModal = this;
                        selfModal.filter = {
                            tipo: "-1"
                        };
                        selfModal.filter.search = '';
                        service.filter = '';
                        delete service.constructors.departamento;
                        delete service.constructors.nivelcargo_cargo;
                        delete service.constructors.dataadmissao;
                        selfModal.entities = service.reload();
                        selfModal.columns = columns;
                        selfModal.values = values;
                        selfModal.title = title.toLowerCase();
                        selfModal.semfiltro = semfiltro;
                        selfModal.enumFilters = enumFilters;
                        selfModal.nameVariable = nameVariable;
                        selfModal.modoselecao = modoselecao;
                        selfModal.modofiltro = modofiltro;
                        selfModal.funcaofiltro = funcaofiltro;
                        selfModal.item = item;

                        selfModal.close = function () {
                            $uibModalInstance.dismiss('cancelar');
                        };

                        selfModal.search = function () {
                            selfModal.entities = service.search(selfModal.filter);
                        };

                        selfModal.reload = function () {
                            if (!selfModal.semfiltro) {
                                selfModal.montaConstrutor();
                            }
                            selfModal.entities = service.reload();
                        };

                        selfModal.isBusy = function () {
                            return service.loadParams.busy;
                        };

                        selfModal.loadMore = function () {
                            return service.loadMore();
                        };

                        selfModal.enumFilter = function (subentity,value) {
                            if(selfModal.enumFilters && selfModal.enumFilters[value] != ''){
                                return $filter(selfModal.enumFilters[value])(subentity[value]);
                            } else {
                                return subentity[value];
                            }
                        };

                        selfModal.finished = function () {
                            return service.loadParams.finished;
                        };

                        selfModal.escolher = function (returnedItem) {
                            if(nameVariable){
                                selfModal.item[nameVariable] = returnedItem;
                            } else {
                                selfModal.item = returnedItem;
                            }
                            if(selfModal.modofiltro){
                                selfModal.funcaofiltro();
                            }
                            $uibModalInstance.dismiss('fechar');
                        };

                        selfModal.montaConstrutor = function () {
                            delete service.constructors.departamento;
                            delete service.constructors.nivelcargo_cargo;
                            delete service.constructors.dataadmissao;
                            service.constructors.status = '00';

                            if (selfModal.filter.tipo) {
                                service.constructors.tipo = selfModal.filter.tipo;
                            }
                            if (selfModal.filter.status) {
                                service.constructors.status = selfModal.filter.status;
                            }
                            if (selfModal.filter.departamento) {
                                service.constructors.departamento = selfModal.filter.departamento.departamento;
                            }
                            if (selfModal.filter.nivelcargo_cargo) {
                                service.constructors.nivelcargo_cargo = selfModal.filter.nivelcargo_cargo.cargo;
                            }

                            let dataadmissao = [];
                            if (selfModal.filter.datainicial) {
                                dataadmissao.push({
                                    condition: 'gte',
                                    value: selfModal.filter.datainicial
                                });
                            }
                            if (selfModal.filter.datafinal) {
                                dataadmissao.push({
                                    condition: 'lte',
                                    value: selfModal.filter.datafinal
                                });
                            }
                            if (dataadmissao.length > 0) {
                                service.constructors.dataadmissao = dataadmissao;
                            }
                        };
                    }],
                    controllerAs: '$ctrl',
                    windowClass: 'nsj-modal-slg',
                    resolve: {
                        entity: function () {
                            return scope;
                        }
                    }
                });
            }
        };

        return selfService;
    }]);