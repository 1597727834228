angular.module('onFileChange', []).directive('onFileChange', function() {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var onFileChange = scope.$eval(attrs.onFileChange);
        element.on('change', onFileChange);
        element.on('$destroy', function() {
          element.off();
        });
  
      }
    };
  });