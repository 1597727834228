// Gerado pelo MDA

angular.module('main')
    .controller('MotivosrescisoesListController', [

        '$rootScope', '$scope', '$state', '$http', '$stateParams', '$location', '$transitions', 'nsjRouting', 'NewToaster', 'Motivosrescisoes',
        function(

            $rootScope, $scope, $state, $http, $stateParams, $location, $transitions, nsjRouting, NewToaster, entityService) {

            var self = this;
            self.busy = false;
            self.service = entityService;
            entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
            self.filters = entityService.filters = {};
            self.search = function(filter) {
                if (filter.search != '') {
                    $location.path($location.path()).search(angular.extend({}, filter.filters, { 'q': filter.search }));
                } else {
                    $location.path($location.path()).search(angular.extend({}, filter.filters));
                }
                return entityService.search(filter);
            };
            self.reload = entityService.reload;
            self.loadMore = function() {
                entityService.loadMore()
            };
            self.constructors = entityService.constructors = [];
            for (var i in $stateParams) {
                if ([].indexOf(i) > -1 && $stateParams[i] !== undefined) {
                    entityService.filters[i] = $stateParams[i];
                } else if (typeof $stateParams[i] != 'undefined' && typeof $stateParams[i] != 'function' && i != 'q' && i != 'motivorescisao') {
                    entityService.constructors[i] = $stateParams[i];
                }
            }


            self.entities = entityService.reload();

            self.fields = [{
                    value: 'descricao',
                    label: 'Descrição',
                    type: 'string',
                    style: 'title',
                    copy: '',
                },

            ];


            self.generateRoute = function(route, params) {
                return nsjRouting.generate(route, params, true);
            };

            self.isBusy = function() {
                return entityService.loadParams.busy;
            };

            $scope.$on("motivosrescisoes_deleted", function(event) {
                self.reload();
            });


            $scope.$on("$destroy", function() {
                if (entityService.loading_deferred) {
                    entityService.loading_deferred.resolve();
                }
            });

            $rootScope.$on("motivosrescisoes_submitted", function(event, args) {
                if (!args.autosave) {
                    self.reload();
                }
            });

        }
    ]);