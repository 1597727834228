angular.module('main').service('RelatoriosFolha', RelatoriosFolha);

RelatoriosFolha.$inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

function RelatoriosFolha($http, nsjRouting, $rootScope, $q) {

    var self = {
        entities: [],
        loaded: false,
        constructors: {},
        after: {},
        filters: {},
        loadParams: {
            to_load: 3,
            busy: false,
            finished: false,
        },
        loading_deferred: null,
        filter: '',
        reload: function () {
            if (self.loading_deferred) {
                self.loading_deferred.resolve();
            }

            self.loadParams.finished = false;
            self.loadParams.to_load = 3;
            self.after = {};
            self.loaded = false;
            self.entities.length = 0;
            self.loadParams.busy = false;

            return self.load();
        },
        search: function (filter) {
            if (typeof filter != 'undefined') {
                self.filter = filter.search;
                Object.keys(self.filters).forEach(function (k) { delete self.filters[k] });
                for (var fil in filter.filters) {
                    if (fil.indexOf('.') > -1) {
                        var filterSplit = fil.split('.');
                        self.filters[filterSplit[0]] = {};
                        self.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                    }
                    else {
                        self.filters[fil] = filter.filters[fil];
                    }
                }
            }

            return self.reload();
        },
        _load: function (constructors, offset, filter) {
            self.loading_deferred = $q.defer();

            return $q(function (resolve, reject) {
                $http({
                    method: 'GET',
                    url: nsjRouting.generate('meurh_folhaspagamentos_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, self.filters), true),
                    timeout: self.loading_deferred.promise
                })
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    });

            });

        },
        load: function () {
            if (!self.loadParams.busy && !self.loadParams.finished && self.loadParams.to_load > 0) {
                self.loadParams.busy = true;

                self._load(self.constructors, self.after, self.filter)
                    .then(function (data) {
                        if (data.length > 0) {
                            for (var i = 0; i < data.length; i++) {
                                self.entities.push(data[i]);
                            }
                            self.after['folhapagamento'] = self.entities[self.entities.length - 1]['folhapagamento'];
                        }
                        if (data.length < 20) {
                            self.loadParams.finished = true;
                            $rootScope.$broadcast("meurh_folhaspagamentos_list_finished", self.entities);
                        } else {
                            self.loadParams.finished = false;
                        }
                        self.loaded = true;

                        self.loadParams.to_load--;
                    })
                    .catch(function (error) {
                        if (error.xhrStatus !== 'abort') {
                            self.loadParams.finished = true;
                        }
                    })
                    .finally(function () {
                        if (self.loaded || self.loadParams.finished) {
                            self.loadParams.busy = false;
                        }
                        self.load();
                    });
            }

            return self.entities;
        },
        loadMore: function () {
            self.loadParams.to_load = 3;
            self.load();
        }

    };

    return self;
}
