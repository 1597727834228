// Gerado pelo MDA

angular.module('main').service('Motivosrescisoes', Motivosrescisoes);

Motivosrescisoes.$inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

function Motivosrescisoes($http, nsjRouting, $rootScope, $q) {

    var self = {
        entities: [],
        loaded: false,
        constructors: {},
        after: {},
        filters: {},
        loadParams: {
            to_load: 3,
            busy: false,
            finished: false,
        },
        loading_deferred: null,
        filter: '',
        reload: function() {
            if (self.loading_deferred) {
                self.loading_deferred.resolve();
            }

            self.loadParams.finished = false;
            self.loadParams.to_load = 3;
            self.after = {};
            self.loaded = false;
            self.entities.length = 0;
            self.loadParams.busy = false;

            return self.load();
        },
        search: function(filter) {
            if (typeof filter != 'undefined') {
                self.filter = filter.search;
                Object.keys(self.filters).forEach(function(k) { delete self.filters[k] });
                for (var fil in filter.filters) {
                    if (fil.indexOf('.') > -1) {
                        var filterSplit = fil.split('.');
                        self.filters[filterSplit[0]] = {};
                        self.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                    } else {
                        self.filters[fil] = filter.filters[fil];
                    }
                }
            }

            return self.reload();
        },
        _load: function(constructors, offset, filter) {
            self.loading_deferred = $q.defer();

            return $q(function(resolve, reject) {
                $http({
                        method: 'GET',
                        url: nsjRouting.generate('motivosrescisoes_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, self.filters), true),
                        timeout: self.loading_deferred.promise
                    })
                    .then(function(response) {
                        resolve(response.data);
                    })
                    .catch(function(response) {
                        reject(response);
                    });

            });

        },
        load: function() {
            if (!self.loadParams.busy && !self.loadParams.finished && self.loadParams.to_load > 0) {
                self.loadParams.busy = true;

                self._load(self.constructors, self.after, self.filter)
                    .then(function(data) {
                        if (data.length > 0) {
                            for (var i = 0; i < data.length; i++) {
                                self.entities.push(data[i]);
                            }
                        }
                        self.loadParams.finished = true;
                        $rootScope.$broadcast("motivosrescisoes_list_finished", self.entities);
                        self.loaded = true;

                        self.loadParams.to_load--;
                    })
                    .catch(function(error) {
                        if (error.xhrStatus !== 'abort') {
                            self.loadParams.finished = true;
                        }
                    })
                    .finally(function() {
                        if (self.loaded || self.loadParams.finished) {
                            self.loadParams.busy = false;
                        }
                    });
            }

            return self.entities;
        },
        loadMore: function() {
            self.loadParams.to_load = 3;
            self.load();
        },
        find: function(identifier) {
            for (var i in self.entities) {
                if (self.entities[i]['motivorescisao'] == identifier) {
                    return self.entities[i];
                }
            }
            return null;
        },
        _save: function(entity, autosave) {
            var method, url;
            if (entity['motivorescisao']) {
                method = "PUT";
                url = nsjRouting.generate('motivosrescisoes_put', { 'id': entity['motivorescisao'] }, true);
            } else {
                method = "POST";
                url = nsjRouting.generate('motivosrescisoes_create', angular.extend(self.constructors), true);
            }
            if (!autosave) {
                autosave = false;
                entity['$$__submitting'] = true;
            }
            var data = angular.copy(entity);
            if (data.hasOwnProperty('$$__submitting')) {
                delete data['$$__submitting'];
            }
            self.loading_deferred = $q.defer();
            return $http({
                    method: method,
                    url: url,
                    data: data,
                    timeout: self.loading_deferred.promise
                })
                .finally(function(response) {
                    if (!autosave) {
                        entity['$$__submitting'] = false;
                    }
                });
        },
        save: function(entity, autosave) {

            self
                ._save(entity, autosave)
                .then(function(response) {
                    if (response.data.motivorescisao) {
                        entity['motivorescisao'] = response.data.motivorescisao;
                    }
                    entity['$$_saved'] = true;
                    $rootScope.$broadcast("motivosrescisoes_submitted", {
                        entity: entity,
                        response: response,
                        autosave: autosave
                    });
                })
                .catch(function(response) {
                    $rootScope.$broadcast("motivosrescisoes_submit_error", {
                        entity: entity,
                        response: response,
                        autosave: autosave
                    });
                });
        },
        get: function(identifier) {
            self.loading_deferred = $q.defer();

            return $q(function(resolve, reject) {
                $http
                    .get(nsjRouting.generate('motivosrescisoes_get', { 'id': identifier }, true), { timeout: self.loading_deferred.promise })
                    .then(function(response) {
                        $rootScope.$broadcast("motivosrescisoes_loaded", response.data);
                        resolve(response.data);
                    })
                    .catch(function(response) {
                        reject(response);
                    });
            });
        }




    };

    return self;
}