angular.module('main')
    .service('ModalPagamentoConfirmService', [
                 '$uibModal', '$rootScope',  'NewToaster',
        function ($uibModal, $rootScope, NewToaster) {
            let selfService = {
                open: function (cnfEntity, tipo, excluir = false, textoPersonalizado = null) {
                    return $uibModal.open({
                        template: require('html-loader!./modal-pagamento-confirm.html'),
                        controller: ['entity', '$uibModalInstance', 'tipo', function ModalPagamentoConfirmModal(entity, $uibModalInstance, tipo) {
                            let selfModal = this;
                            selfModal.entity = entity;
                            selfModal.possuierro = false;

                            selfModal.mesesFiltrados = [{
                                valor: '1',
                                mes: 'Janeiro'
                            }, {
                                valor: '2',
                                mes: 'Fevereiro'
                            }, {
                                valor: '3',
                                mes: 'Março'
                            }, {
                                valor: '4',
                                mes: 'Abril'
                            }, {
                                valor: '5',
                                mes: 'Maio'
                            }, {
                                valor: '6',
                                mes: 'Junho'
                            }, {
                                valor: '7',
                                mes: 'Julho'
                            }, {
                                valor: '8',
                                mes: 'Agosto'
                            }, {
                                valor: '9',
                                mes: 'Setembro'
                            }, {
                                valor: '10',
                                mes: 'Outubro'
                            }, {
                                valor: '11',
                                mes: 'Novembro'
                            }, {
                                valor: '12',
                                mes: 'Dezembro'
                            }];
                            var currentYear = (new Date()).getFullYear();
                            selfModal.anosFiltrados = [{
                                valor: (currentYear-1).toString(),
                                ano: (currentYear-1).toString()
                            }, {
                                valor: currentYear.toString(),
                                ano: currentYear.toString()
                            }, {
                                valor: (currentYear+1).toString(),
                                ano: (currentYear+1).toString()
                            }];

                            // Texto personalizado
                            if (textoPersonalizado) {
                                selfModal.textos = textoPersonalizado;
                            }

                            selfModal.verificaoMesAno = function () {
                                mesaux = entity.mes;
                                anoaux = entity.ano;
                                if (entity.feechamentoProximoMes) {
                                    mesaux = mesaux + 1;
                                    if (mesaux === 13) {
                                        anoaux = anoaux + 1;
                                        mesaux = 1;
                                    }
                                }
                                let data = entity.diaFechamento + '/' + mesaux + '/' + anoaux
                                let datafinal = moment(data, 'DD-MM-YYYY');
                                return moment().startOf('day').isAfter(datafinal);
                            };

                            //
                            selfModal.confirmar = function () {
                                $uibModalInstance.close(selfModal.entity);
                            };
                            selfModal.close = function () {
                                $uibModalInstance.dismiss('cancelar');
                            };
                        }],
                        controllerAs: 'confirm',
                        windowClass: '',
                        backdrop: 'static',
                        resolve: {
                            entity: function () {
                                return cnfEntity;
                            },
                            tipo: function () {
                                return tipo;
                            }
                        }
                    });
                }
            };

            return selfService;
        }]);