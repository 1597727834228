angular.module("CKEditor", [])
.directive('ckEditor', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attr, ngModel) {

            const ck = CKEDITOR.replace(elm[0], scope.$eval(attr.config) ? scope.$eval(attr.config) : {});

            function updateModel() {
                const data = ck.getData();
                if (data === ngModel.$viewValue) return;
                scope.$applyAsync(function () {
                    ngModel.$setViewValue(data);
                });
            }
            function updateCkData() {
                if (ngModel.$viewValue === undefined) return;
                ck.setData(ngModel.$viewValue);
            }

            ck.on('instanceReady', updateCkData);
            ck.on('change', updateModel);
            ngModel.$render = updateCkData;
        }
    };
});