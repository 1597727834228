let StringMask = require('string-mask');

angular.module('timeMask', []).directive("timeMask", ['$filter', function($filter) {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function(scope, element, attrs, ctrl) {
			timeFormat = '000:00';
            timeFormatAlt = '00:00';
            let formatAltApplied = false; // decide se a formatacao 00:00 é utilizada ou não

			let formattedValueLength = timeFormat.length;
			let unformattedValueLength = timeFormat.replace(':', '').length;
			let timeMask = new StringMask(timeFormat);
            let timeMaskAlt = new StringMask(timeFormatAlt);

			let hasFocus = false;

			element.on('focus', function() {
				hasFocus = true;
			});

			element.on('blur', function() {
				hasFocus = false;
			});

			scope.hasFocus = function() {
				return hasFocus;
			};

			function formatter(value) {
				if (ctrl.$isEmpty(value)) {
					return value;
				}
                if (typeof value === 'number') {
                    value = $filter('floatToTimeString')(value);
                }
				// limpa o valor, deixando apenas numeros
				let cleanValue = value.replace(/[^0-9]/g, '').slice(0, unformattedValueLength) || '';

                if (cleanValue.length < 5) {
                    formatAltApplied = true;
                    return (timeMaskAlt.apply(cleanValue) || '').replace(/[^0-9]$/, '');
                }
                formatAltApplied = false;
				return (timeMask.apply(cleanValue) || '').replace(/[^0-9]$/, '');
			}

			ctrl.$formatters.push(formatter);

			ctrl.$parsers.push(function parser(value) {
				if (ctrl.$isEmpty(value)) {
					return value;
				}

				let viewValue = formatter(value);
                
				if (ctrl.$viewValue !== viewValue) {
					ctrl.$setViewValue(viewValue);
					ctrl.$render();
				}
				// o valor é convertido em float (sem modificar a exibicao do input)
                value = $filter('timeStringToFloat')(viewValue);
				return value;
			});

			ctrl.$validators.time = function(modelValue, viewValue) {
				if (ctrl.$isEmpty(viewValue)) {
					return true;
				}

				// números decimais com no máximo 3 dígitos na parte inteira
				const regexDecimal = /^\d{1,3}\.?\d{1,2}$/;

				// verificando se valor do model é uma string no formato de números decimais
				if (regexDecimal.test(modelValue)) {

					// valor do model no formato hh:mm
					let modelValueFormat = $filter('floatToTimeString')(parseFloat(modelValue));

					// se o valor formatado do model for diferente do que está sendo exibido na view, modificar a view para o valor do model formatado.
					// hasFocus para garantir não fazer a modificação enquanto o usuário está digitando
					if (modelValueFormat !== viewValue && !hasFocus) {
						ctrl.$setViewValue(modelValueFormat);
						ctrl.$render();
					}
				}

				let splittedValue = viewValue.toString().split(/:/).filter(function(v) {
					return !!v;
				});
				let minutes = parseInt(splittedValue[1]);

                let isValid = false;
                if (formatAltApplied) {
                    isValid = viewValue.toString().length === formattedValueLength-1 && minutes < 60;
                } else {
                    isValid = viewValue.toString().length === formattedValueLength && minutes < 60;
                }
				return isValid;
			};
		}
	}
}]);