export enum StatusTrabalhadorEnum {
  'Atividade Normal' = '01',
  'Contrato Rescindido' = '02',
  'Férias' = '03',
  'Licença Médica' = '04',
  'Acidente de Trabalho' = '05',
  'Licença Maternidade' = '06',
  'Licença Paternidade' = '07',
  'Serviço Militar' = '08',
  'Mandato Sindical' = '09',
  'Licença não remunerada' = '10',
  'Qualificação' = '11',
  'Cárcere' = '12',
  'Suspenso' = '13',
  'Mandato Eleitoral' = '14',
  'Cessão' = '15',
  'Aposentadoria por invalidez' = '16',
  'Outros' = '17'
}
