angular
    .module('main')
    .filter
    (
    'countAvisos', function () {
        return function (avisos, param) {
            var countA = 0;
            var countP = 0;
            var countTotal = 0

            angular.forEach(avisos, function (v, k) {
                if (param == 'avisos' && v.tipo_pendencia == 6) {
                    countA++;
                } else if (param == 'pendencias' && v.tipo_pendencia !== 6){
                    countP++;
                } else{
                    countTotal++;
                }
            });

            if (param == 'avisos') {
                return countA;
            } else if (param == 'pendencias'){
                return countP;
            } else {
                return countTotal;
            }

        };
    }
    )
    .filter('timeStringToFloat', function () {
        return function (time) {
            var hoursMinutes = time.split(/[.:]/);
            var hours = parseInt(hoursMinutes[0], 10);
            var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
            return (hours + minutes / 60).toFixed(2);
        };
    })
    .filter('floatToTimeString', function () {
        return function (timeFloat) {
            if (timeFloat) {
                var hours = parseInt(timeFloat);
                var minutes = (timeFloat - hours) * 60;
                return hours.toString().padStart(2, "0") + ":" + Math.round(minutes).toString().padStart(2, "0");
            } else {
                return null;
            }
        };
    })
    .filter
    (
    'dashboardAlertMsg', function () {
        return function (tipo, dias, descricao) {
            var msg = "";
            switch (tipo) {
                case 0:
                    msg = "Funcionário(a) próximo(a) do vencimento de férias";
                    break;
                case 1:
                    msg = "Funcionário(a) com " + dias + " dias de férias vencidas";
                    break;
                case 2:
                    msg = "Funcionário(a) com " + dias + " dias restantes para dobra de férias";
                    break;
                case 3:
                    msg = "Funcionário(a) com " + dias + " dias de férias dobradas";
                    break;
                case 4:
                    if (dias === 0){
                       msg = "Hoje é o fim do periodo de experiência!";
                    }else
                        msg = "Faltam " + dias + " dias para o fim do periodo de experiência";
                    break;
                case 5:
                    msg = "Faltam " + dias + " dias para o envio do Apontamento dos funcionários";
                    break;
                case 6:
                    msg = "Documento do funcionário adicionado";
                    break;
                case 14:
                    msg = "Funcionário contratado!";
                    break;
                case 8:
                    msg = "Funcionário não faz mais parte do corpo de colaboradores";
                    break;
                case 9:
                    msg = "Férias Confirmadas!";
                    break; 
                case 10:
                    msg = "VT Adicional efetuado! "+descricao+".";
                    break; 
                case 11:
                    msg = "VT Adicional rejeitado! "+descricao+".";
                    break;
                case 12:
                    msg = "Solicitação de alteração de VT efetuada";
                    break;
                case 13:
                    msg = "Solicitação de alteração de VT rejeitada";
                    break;
                case 15:
                    msg = "Adiantamento avulso efetuado! " + descricao;
                    break;
                case 16:
                    msg = "Adiantamento avulso rejeitado! " + descricao;
                    break;
                case 17:
                    msg = "Faltam " + dias + " dias para o vencimento da CNH";
                    break;
                case 18:
                    msg = "Faltam " + dias + " dias para o vencimento do Orgão de Classe";
                    break;
            }
            return msg;
        };
    }
    )
    .filter
    (
    'srcUrl', function ($sce) {
        return function (url) {
            var newUrl = 'https://docs.google.com/gview?url=' + url + '&embedded=true';
            return $sce.trustAsResourceUrl(newUrl);
        };
    }
    )

    .filter
    (
    'sumOfValue', function () {
        return function (data, key) {
            // debugger;
            if (angular.isUndefined(data) && angular.isUndefined(key))
                return 0;
            var sum = 0;

            angular.forEach(data, function (v, k) {
                if (typeof v[key] != 'undefined') {
                    sum = sum + v[key];
                }
            });
            return sum;
        };
    }
    )

    .filter
    (
    'getSaldo', function () {
        return function (data, direito) {
            if (angular.isUndefined(data))
                return 0;
            var sum = 0;

            angular.forEach(data.ferias, function (v, k) {
                if (typeof v.diasferiascoletivas != 'undefined') {
                    if (isNaN(v.diasferiascoletivas)) {
                        sum = sum + 0;
                    } else {
                        sum = sum + v.diasferiascoletivas;
                    }
                }

                if (typeof v.diasvendidos != 'undefined') {
                    if (isNaN(v.diasvendidos)) {
                        sum = sum + 0;
                    } else {
                        sum = sum + v.diasvendidos ;
                    }
                }
            });
            // var diasvendidos = 0;
            
            // if (typeof data.diasvendidos != 'undefined') {
            //     diasvendidos = data.diasvendidos ? direito / 3 : 0;
            // }
            return direito - sum;
        };
    }
    )

    .filter
    (
    'addDaysFilter',
    function () {
        return function (input, days) {
            if (input !== '') {
                var inputDate = new Date(input);
                return new Date(inputDate.getTime() + days * 24 * 60 * 60 * 1000);
            } else {
                return '';
            }
        };
    }
    )
    .filter
    (
    'zeroEsquerdaFilter',
    function () {
        return function (input, n) {
            if (!input)
                input = "";
            if (input.length >= n)
                return input;
            var zeros = "0".repeat(n);
            return (zeros + input).slice(-n);
        };
    }
    )

    .filter
    (
    'cpfFilter',
    function () {
        return function (input) {
            if (!input)
                input = "";

            var cpf = input.replace(/(\d{3})(\d)/, "$1.$2");
            cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
            cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
            return cpf;
        };
    }
    )

    .filter
    (
    'cepFilter',
    function () {
        return function (input) {
            if (!input)
                input = "";

            var cep = input.replace(/(\d)(\d{3})$/, "$1-$2");
            return cep;
        };
    }
    )

    .filter
    (
    'telefoneFilter',
    function () {
        return function (telefone, ddd) {
            if (!telefone){
                return "Não informado."
            }

            var tel = telefone.replace(/(\d)(\d{4})$/, "$1-$2");

            if (ddd){
                tel = ddd + ' ' + tel;
            }
            
            return tel;
        };
    }
    )
    .filter('rangeFilter', function () {
        return function (input, min, max) {
            min = parseInt(min);
            max = parseInt(max);
            
            if(max > min){
                for (var i = min; i <= max; i++)
                    input.push(i);
            }else{
                for (var i = min; i >= max; i--)
                    input.push(i);
            }
            
            return input;
        };
    })
    .filter('propsFilter', function() {
        return function(items, props) {
          var out = [];
      
           if (angular.isArray(items)) {
            items.forEach(function(item) {
              var itemMatches = false;
      
               var keys = Object.keys(props);
              for (var i = 0; i < keys.length; i++) {
                var prop = keys[i];
                var text = props[prop].toLowerCase();
                if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                  itemMatches = true;
                  break;
                }
              }
      
               if (itemMatches) {
                out.push(item);
              }
            });
          } else {
            // Let the output be the input untouched
            out = items;
          }
      
           return out;
        }
      });
    ;
