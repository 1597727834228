import angular from 'angular';

export class PersonaNiveisCargos {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public entities: any = [];
    public loaded: boolean = false;
    public constructors: any = {};
    public after: any = {};
    public filters: any = {};
    public loadParams: any = {
        to_load: 3,
        busy: false,
        finished: false,
    };
    public loading_deferred: any = null;
    public filter: any = '';
    loading_deferred_filter: any;

    constructor(
        public $http: any,
        public nsjRouting: any,
        public $rootScope: angular.IRootScopeService,
        public $q: any
    ) { }

    reload() {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }

    search(filter: any) {
        let filters: any = {};
        let search = '';
        if (typeof (filter) !== 'undefined' && filter != null) {
            search = filter.search ? filter.search : '';
            for (let fil in filter.filters) {
                if (typeof (filter.filters[fil]) !== 'object') {
                    filters[fil] = filter.filters[fil];
                } else if (typeof (filter.filters[fil]) === 'object' && filter.filters[fil].hasOwnProperty('startDate')) {
                    filters[fil] = {
                        inicio: { condition: 'gte', value: filter.filters[fil].startDate },
                        fim: { condition: 'lte', value: filter.filters[fil].endDate }
                    };
                } else if (filter.filters[fil].constructor === Array) {
                    for (let pos in filter.filters[fil]) {
                        if (filter.filters[fil][pos] === undefined ||
                            (typeof (filter.filters[fil][pos]) === 'object' && filter.filters[fil][pos].value === undefined) ||
                            (typeof (filter.filters[fil][pos]) === 'object' && filter.filters[fil][pos].value === null)) {
                            delete filter.filters[fil][pos];
                        }
                    }
                    filters[fil] = filter.filters[fil];
                } else if (typeof (filter.filters[fil]) === 'object' && filter.filters[fil].value !== undefined && filter.filters[fil].value !== null) {
                    filters[fil] = filter.filters[fil];
                }
            }
            if (filter.filterLookup && filter.filterLookup.tiposatividadesfilter) {
                filters.tiposatividadesfilter = filter.filterLookup.tiposatividadesfilter.tipoatividade;
            }
        } else if (filter == null) {
            if (this.loading_deferred_filter) {
                this.loading_deferred_filter.resolve();
            }

            return this._load(this.constructors, {}, '');
        }
        if (filter.lookup) {
            if (this.loading_deferred_filter) {
                this.loading_deferred_filter.resolve();
            }

            return this._load(this.constructors, {}, search);
        } else {
            this.filter = search;
            this.filters = filters;
            return this.reload();
        }
    }

    _load(constructors: any, offset: any, filter: any) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve, reject) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('persona_niveiscargos_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })

                .then((response) => {
                    resolve(response.data);
                })
                .catch((response) => {
                    reject(response);
                });

        });

    }

    load() {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data) => {
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }
                    }
                    this.loadParams.finished = true;
                    this.$rootScope.$broadcast('persona_niveiscargos_list_finished', this.entities);
                    this.loaded = true;

                    this.loadParams.to_load--;
                })
                .catch((error) => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                })
                .finally(() => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }
                });
        }

        return this.entities;
    }

    loadMore() {
        this.loadParams.to_load = 3;
        this.load();
    }

    find(identifier: any) {
        for (let i in this.entities) {
            if (this.entities[i]['nivelcargo'] === identifier) {
                return this.entities[i];
            }
        }
        return null;
    }

    _save(entity: any, autosave: any) {
        var method, url;
        if (entity['nivelcargo']) {
            method = 'PUT';
            url = this.nsjRouting.generate('persona_niveiscargos_put', { 'id': entity['nivelcargo'] }, true);
        } else {
            method = 'POST';
            url = this.nsjRouting.generate('persona_niveiscargos_create', angular.extend(this.constructors), true);
        }
        if (!autosave) {
            autosave = false;
            entity['$$__submitting'] = true;
        }
        var data = angular.copy(entity);
        if (data.hasOwnProperty('$$__submitting')) {
            delete data['$$__submitting'];
        }
        this.loading_deferred = this.$q.defer();
        return this.$http({
                method: method,
                url: url,
                data: data,
                timeout: this.loading_deferred.promise
            })
            .finally((response) => {
                if (!autosave) {
                    entity['$$__submitting'] = false;
                }
            });
    }

    save(entity: any, autosave: any) {

        this
            ._save(entity, autosave)
            .then((response) => {
                if (response.data.nivelcargo) {
                    entity['nivelcargo'] = response.data.nivelcargo;
                }
                entity['$$_saved'] = true;
                this.$rootScope.$broadcast('persona_niveiscargos_submitted', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            })
            .catch((response) => {
                this.$rootScope.$broadcast('persona_niveiscargos_submit_error', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            });
    }

    get(identifier: any) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve, reject) => {
            this.$http
                .get(this.nsjRouting.generate('persona_niveiscargos_get', { 'id': identifier }, true), { timeout: this.loading_deferred.promise })
                .then((response) => {
                    this.$rootScope.$broadcast('persona_niveiscargos_loaded', response.data);
                    resolve(response.data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    }
}

angular.module('main').service('PersonaNiveiscargos', PersonaNiveisCargos);
